import React, { useEffect, useState } from "react";
import { Accordion, Table, Button, Card } from "react-bootstrap";
import finalogo from "../images/spropad/finalogo.png";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import grayline from "../images/spropad/grayline.png";
import Stakemodal from "./stakemodal";
import Unstakemodal from './unstakemodal'
import { ONEDAYINSECONDS } from "../Config/env";
import { getAccount } from "../Hooks/useAccounts";
import {
  approveContract,
  checkIsApproved,
  Claim,
  getUser,
  getUserBalance,
  getUserReward,
  UseTokenInfo,
} from "../Hooks/useStake";
import { toFixedNumber } from "../Hooks/useContract";
import config from '../Config/config'
function Accordiontable(props) {
  console.log("props", props);
  const [accountInfo, setAccountInfo] = useState(getAccount());
  console.log("accountinfos",accountInfo);
  const [accordionOpen, setAccordionOpen] = useState();
  const toggleAccordion = (i) => {
    setAccordionOpen(i == accordionOpen ? null : i);
  };
  const [accordiontable, setAccordiontable] = useState(props.pool || []);
  const [stakemodal, setStakemodal] = useState(false);
  const [unstakemodal, setUnstakemodal] = useState(false);

  const [pooldays, setpoolDays] = useState("");
  console.log("pooldays", pooldays);

  const [tokenInfo, setTokenInfo] = useState(null);
  console.log("tokeninfo", tokenInfo);
  const [userstakedate, setuserstakedate] = useState("-");
  const [status, setStatus] = useState(false);
  const [userStakedAmount, setUserStakedAmount] = useState(0);
  console.log("userStakedAmount", userStakedAmount);
  const [isApproved, setIsApproved] = useState(false);
  console.log("isApproved", isApproved)
  const [pending, setPending] = useState(0);
  console.log("pending", pending)


  const [approving, setApprove] = useState(false)
  console.log("approvinggg", approving)

  const [userTokenBalance, setUserTokenBalance] = useState("");
  console.log("userTokenBalance", userTokenBalance)

  useEffect(() => {
    console.log("Props?.datastakind" , props);
    if (accountInfo) {
      getData();
      approveFunction()

      if (props?.datas?.lpToken && props?.datas?.lpToken) {
        approveFunction(props?.datas?.lpToken)
      }
      getUserTokenBalance();

    }
  }, [accountInfo, isApproved]);

  useEffect(() => {

    setAccountInfo(getAccount());

}, []);


  const getData = async () => {
    var poolday = parseInt(props?.datas?.lockedPeriod) / ONEDAYINSECONDS;
    console.log("pooldaysss",poolday);
    setpoolDays(String(poolday).substring(0, 3));
    if (poolday <= 0) {
      setStatus(true);
    }

    var getuser = await getUser(props.pid, accountInfo);
    const datee = parseInt(getuser?.userLockedPeriod) * 1000;
    // const unstakeDate = datee.toLocaleDateString();

    if (getuser?.userLockedPeriod > 0) setuserstakedate(datee);

    var TokenInfo = await UseTokenInfo(props?.datas?.lpToken);
    console.log("TokenInfo", TokenInfo);
    setTokenInfo(TokenInfo);
    setUserStakedAmount(getuser?.amount);

    var UserReward = await getUserReward(props.pid, accountInfo);
    const dataF = (parseInt(UserReward) + parseInt(getuser.pendingRewards)) / 10 ** parseInt(TokenInfo?.decimals);
    console.log("dataFF", dataF.toFixed(2))
    let fixnumber = toFixedNumber(dataF)
    console.log("fixnumber", parseFloat(fixnumber).toFixed(8));
    setPending(parseFloat(fixnumber).toFixed(8));
  };
  const approve = async () => {
    if (accountInfo) {

      let approving = await approveContract(accountInfo, props?.datas?.lpToken);
      console.log("approving", approving);
      setIsApproved(true);
    }

  }
  const approveFunction = async (data) => {
    if (data) {
      const approval = await checkIsApproved(accountInfo, data ? data : tokenInfo.addres);
      console.log("approval", approval);
      setIsApproved(approval);
      setApprove(approval)


    }
  }
  const getUserTokenBalance = async () => {

    if (props?.datas?.lpToken) {
        var userbalance = await getUserBalance(props?.datas?.lpToken, accountInfo)

        setUserTokenBalance(userbalance);
    }
}

  const claim = async () => {
    await Claim(getAccount(), props.pid)
  }
  console.log("userstakedate" , new Date(userstakedate), (props.unstakeDate ? new Date(props.unstakeDate).getTime() : new Date(userstakedate).getTime()) , (props.unstakeDate ? new Date(props.unstakeDate).getTime() : new Date(userstakedate).getTime()) > Date.now());
  return (
    <>
      {/* <Modal> */}
      {stakemodal && <Stakemodal datas={props.datas} pid={props.pid} tokenInfo={tokenInfo} accountInfo={props.accountInfo} onDismiss={() => setStakemodal(false)} />}
      {unstakemodal && <Unstakemodal datas={props.datas} pid={props.pid} tokenInfo={tokenInfo} totalDeposit={userStakedAmount} unstakeDate={userstakedate} accountInfo={props.accountInfo} onDismiss={() => setUnstakemodal(false)} />}
      <Accordion.Item eventKey={props.pid + 1}>
        <Accordion.Header
          onClick={() => toggleAccordion(props.pid + 1)}
          className="me-4"
        >
          <div className="dgrid">
            <div className="d-block d-xl-none">
              {" "}
              {accordionOpen == props.pid + 1 ? (
                <p className="grnclr">
                  <IoMdArrowDropup />
                </p>
              ) : (
                <p className="grnclr">
                  <IoMdArrowDropdown />
                </p>
              )}
            </div>
            <div className="d-flex">
              <img src={finalogo} className="img-fluid finalogo me-2" />
              <div>
                <h5 className="fname">
                  {pooldays || 0} - days staking pool (
                  {Number(props?.datas?.allocPoint) / 100}x)
                </h5>
                <p className="grayclr mb-0">
                  {Number(props.datas.pointX) / 100}x multiplier
                </p>
              </div>
            </div>
            <div>
              <p className="grayclr mb-3">Earned</p>
              <h5 className="fname mb-0"> {pending || 0} {(tokenInfo && tokenInfo?.name) || ""}</h5>
            </div>
            <div>
              {" "}
              <p className="grayclr mb-3">APR</p>
              <h5 className="fname mb-0">
                {(
                  (props?.datas?.MetaPerBlock /
                    10 ** Number(tokenInfo && tokenInfo?.decimals)) *
                  Number(pooldays)
                ).toFixed(2)}
                %
              </h5>
            </div>
            {/* <div>
              {" "}
              <p className="grayclr mb-3">Remaining</p>
              <h5 className="fname mb-0">3.5454bd.48 SPPAD</h5>
            </div> */}
            <div>
              {" "}
              <p className="grayclr mb-3">lock-up term</p>
              <h5 className="fname mb-0">{pooldays || 0} days</h5>
            </div>
            {/* <div>
              {" "}
              <p className="grayclr mb-3">withdrawal delay time</p>
              <h5 className="fname mb-0">none</h5>
            </div> */}
            <div className="d-none d-xl-block">
              {" "}
              {accordionOpen == props.pid + 1 ? (
                <p className="grnclr">
                  Hide <IoMdArrowDropup />
                </p>
              ) : (
                <p className="grnclr">
                  Details <IoMdArrowDropdown />
                </p>
              )}
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body className="pt-0 pb-5">
          <img
            src={grayline}
            className="img-fluid grayline d-none d-lg-block"
          />
          <div className="row">
            <div className="col-xl-7 col-xxl-8 mt-4">
              <div className="row">
                <div className="col-md-7 col-lg-7">
                  <div className="d-flex wfull align-items-center">
                    <p className="grayclr mb-0">Staked Pools Amount :</p>
                    <p className="ms-4 whtsclr mt-1 mb-0">
                      {(userStakedAmount &&
                        (
                          parseInt(userStakedAmount) /
                          10 ** parseInt(tokenInfo && tokenInfo?.decimals)
                        ).toFixed(2)) ||
                        0}{" "}{tokenInfo?.symbol}
                    </p>
                  </div>
                  <div className="d-flex wfull align-items-center mt-3">
                    <p className="grayclr mb-0">
                      Recent {(tokenInfo && tokenInfo?.name) || ""} Reward :
                    </p>
                    <p className="ms-4 whtsclr mt-1 mb-0">
                      {pending || 0} {(tokenInfo && tokenInfo?.name) || ""}
                    </p>
                  </div>

                  <div className="d-flex wfull align-items-center mt-3">
                    <p className="grayclr mb-0">stake amount (min) :</p>
                    <p className="ms-4 whtsclr mt-1 mb-0">
                      {config?.MinStakeAmt}{" "}{tokenInfo?.symbol} / 1 person
                    </p>
                  </div>

                  <div className="d-flex wfull align-items-center mt-3">
                    <p className="grayclr mb-0">Available balance amount :</p>
                    <p className="ms-4 whtsclr mt-1 mb-0">
                    {userTokenBalance}{" "}{(tokenInfo && tokenInfo?.name) || ""}
                    </p>
                  </div>
                </div>
                <div className="col-md-5 col-lg-4 mt-4 mt-lg-0">
                  {
                    pending > 0 ?

                      <Button className="btn claimbtn mt-4" onClick={claim}>
                        <span className="ctext">Claim Token</span>
                      </Button>
                      : ""
                  }
                  {/* <Button className="btn claimbtn mt-4">
                    <span className="ctext">Stake Rewards</span>
                  </Button> */}

                </div>
              </div>
            </div>
            <div className="col-xl-5 col-xxl-4 mt-4">
              {/* <Button
                className="themesbtn mt-4"
                onClick={() => setStakemodal(true)}
              > */}
              {/* <div className="d-flex wfull align-items-center mt-3"> */}
              {
                userStakedAmount > 0 &&
                <div className="d-flex wfull align-items-center mt-3">

                  <p className="grayclr mb-0">Unstake available after :</p>
                  <p className="ms-4 whtsclr mt-1 mb-0">
                    {props.unstakeDate ? new Date(props.unstakeDate)?.toLocaleDateString() : new Date(userstakedate)?.toLocaleDateString()}
                  </p>
                </div>

              }

              {/* </div> */}

              {approving !== true ? <Button className='themesbtn' onClick={() => approve()}> <span>Approve</span> </Button>
                :
                <Button className="themesbtn mt-4" onClick={() => setStakemodal(true)} > <span>Stake</span> </Button>
              }
              {approving == true && userStakedAmount > 0 ?
                <Button className='themesbtn'
                // disabled = {(props?.unstakeDate ? new Date(props?.unstakeDate).getTime() :parseInt(userstakedate)) > Date.now()}
                onClick={() => setUnstakemodal(true)}>
                  <span>Unstake</span>
                </Button> : ""
              }

              {/* {
                approving == true ? <Button className="themesbtn mt-4" onClick={() => setStakemodal(true)} > <span>Stake</span> </Button> :
                  approving == true ? <Button className='themesbtn' onClick={() => setUnstakemodal(true)}><span>Unstake</span></Button> :
                    <Button className='themesbtn' onClick={() => approve()}> <span>Approve</span> </Button>
              } */}

            </div>

          </div>

        </Accordion.Body>
      </Accordion.Item>
      {/* </Modal> */}
    </>
  );
}

export default Accordiontable;
