import React, { useState, useEffect } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import smallrect from "../images/spropad/smallrectangle.png";
import Countdown from 'react-countdown';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router';
import { AddRegisterInterest, CheckRegisterInterest, GetSalePerAccount, checkIsApproved, getAllocationInfo, getSaleInfoCard } from '../Hooks/useContract';
import { getAccount } from '../Hooks/useAccounts';
import { AddInterestHooks, GetSingleSaleHooks } from '../Hooks/UseBackenduser';
import { iconTheme, position, style } from '../Hooks/useToast';

function Upcoming() {
    const location = useLocation()
    console.log("locationssssss", location);
    // const salerecords = location?.state
    const [salerecords , setSalerecords] = useState({});
    console.log("salerecords", salerecords);
    const [userdata, setUserdata] = useState([])
    console.log("userdata", userdata);

    const [allocatedata, setAllocatedata] = useState([])
    console.log("userclaimables", allocatedata);
    const [approval, setApproval] = useState([]);
    const [proinfo , setProinfo] = useState({});
    const [registerstatus , setRegisteruser] = useState(false);

    useEffect(() => {
        claimabledata()
        approveFunction()
    }, [])
    const isUpcoming = (start, end) => {
        try {
            return (Date.now() < (start * 1000));
        }
        catch (e) {
            console.log("isUpcoming", e);
        }
    }
    const renderer = ({ hours, minutes, seconds, completed, days }) => {
        // console.log("hoursssmituesss", hours, minutes, seconds, completed, days);
        if (completed) {
            // Render a completed state
            return <span>Waiting for Owner To Accept</span>;
        } else {
            // Render a countdown
            return (
                <div className='d-flex mt-3'>
                    <div>
                        <span className='timerbox whtsclr px-3 py-3'>{formatTime(days)}</span>
                        <p className='grayclr text-center mt-2 f-16'>Days</p>
                    </div>
                    <p className='whtsclr mb-0 ms-1 ms-sm-2'>:</p>
                    <div className='ms-2 ms-xxl-3'>
                        <span className='timerbox whtsclr px-3 py-3'>{formatTime(hours)}</span>
                        <p className='grayclr text-center mt-2 f-16'>Hrs</p>
                    </div>
                    <p className='whtsclr mb-0 ms-1 ms-sm-2'>:</p>
                    <div className='ms-2 ms-xxl-3'>
                        <span className='timerbox whtsclr px-3 py-3'>{formatTime(minutes)}</span>
                        <p className='grayclr text-center mt-2 f-16'>Min</p>
                    </div>
                    <p className='whtsclr mb-0 ms-1 ms-sm-2'>:</p>
                    <div className='ms-2 ms-xxl-3'>
                        <span className='timerbox whtsclr px-3 py-3'>{formatTime(seconds)}</span>
                        <p className='grayclr text-center mt-2 f-16'>Sec</p>
                    </div>
                </div>
            );
        }
    };

    const formatTime = (time) => {
        return String(time).padStart(2, "0");
    };

    const [volumee, setvolumee] = useState(60)
    const handleOnChange = (value) => {
        setvolumee(value)
    };

    const padToDigits = (num) => {
        return num.toString().padStart(2, '0')
    }
    const sliderStyles = {
        pointerEvents: 'none', // Disables all pointer events
        opacity: 0.9 // Optionally, reduce opacity to visually indicate it's non-interactive
    };

    const [interest, setInterset] = useState(true)
    const register = () => {
        setInterset(false)
        toast("Wow so easy!");
    }

    const UpcomingDiffernce = (start) => {
        console.log("starttss", start);
        try {
            console.log("returnvaluess", start, (start * 1000) - Date.now());

            return ((start * 1000) - Date.now());
        }
        catch (e) {
            console.log("UpcomingDiffernce", e);
        }
    }

    const isSaleLive = (start, end, isOpen) => {
        try {
            return (Date.now() >= (start * 1000) && Date.now() <= (end * 1000)) && isOpen;
        }
        catch (e) {
            console.log("isSaleLive", e);
        }
    }

    const isInterestLive = (start, end, isOpen) => {
        try {
            return (Date.now() >= (start * 1000) && Date.now() <= (end * 1000))
        }
        catch (e) {
            console.log("isSaleLive", e);
        }
    }

    const Salediffernce = (end) => {
        try {
            return ((end * 1000) - Date.now());
        }
        catch (e) {
            console.log("Salediffernce", e);
        }
    }
    const approveFunction = async (accountInfo, tokenAddress, saleAddress) => {
        const approval = await checkIsApproved(accountInfo, tokenAddress, saleAddress);
        console.log("approval", approval);
        setApproval(approval)


    }
    const claimabledata = async () => {
        let result = await getSaleInfoCard(window.location.href?.split("/")[4])
        console.log("upcoming data" , result);
        if(result?.length > 0){
            setSalerecords(result)

        }
        if (localStorage.getItem("accountInfo")) {
            console.log("localStorage",localStorage.getItem("accountInfo"));
            const user = await GetSalePerAccount(localStorage.getItem("accountInfo"), salerecords?.saleAddress);
            console.log("user", user);
            setUserdata(user);

            if (salerecords?.isClaimable) {
                console.log("isClaimable", salerecords?.isClaimable);

                const allocated = await getAllocationInfo(userdata.actualBalance, userdata.userClaimbale, salerecords.vestingInterval, salerecords.vestingPercent, userdata.initialClaim);
                console.log("allocated", allocated);
                setAllocatedata(allocated);

            }
            if (!salerecords?.buytype) {
                console.log("buytype", !salerecords?.buytype);

                approveFunction(localStorage.getItem("accountInfo"), salerecords?.useWithToken, salerecords?.saleAddress)
            }
            let reguser = await CheckRegisterInterest(window.location.href?.split("/")[4]);
        setRegisteruser(reguser);

        }
        
        let saleres = await GetSingleSaleHooks({saleaddress : window.location.href?.split("/")[4]})
        setProinfo(saleres?.data?.record);

        
    }

    const handleInterest = async() => {
        try{
            if(getAccount()){
                let payload = {
                    saleaddress : window.location.href.split("/")[4],
                    address : getAccount()
                }
                let result = await AddInterestHooks(payload)
                console.log("result" , result);
                if(result?.data?.type == "Success"){
                    claimabledata()
                    toast.success("Register Interest Successfully!")
                }
            }
        }
        catch(e){
            console.log("Error on handle interest" , e);
        }
    }

    const AddInterest = async() => {
            let res = await AddRegisterInterest(window.location.href?.split("/")[4]);
        
    }



    return (

        <div>
            <div className='timezone py-4 px-3 px-lg-2 px-xxl-3'>
{console.log("proinfo?.interest?.includes(getAccount()" , proinfo?.interest?.includes(getAccount()))}
{/* parseFloat(salerecords?.regStartTime) * 1000 <= Date.now() && parseFloat(salerecords?.regEndTime) * 1000 >= Date.now()  &&  */}
                {!getAccount() || !registerstatus ?
                    <div>
                        <h4 className='fname'>Join Interest</h4>
                        {/* The interset is not yet open. */}
                        <p className='grayclr smtxt'></p>
                        <Button className='themesbtn mx-auto' disabled = {!getAccount() || !isInterestLive(salerecords.regStartTime , salerecords?.regEndTime)} onClick={() => AddInterest()}><span>Register interest</span></Button>
                    </div>
                    :
                    <div className='d-flex'>
                        <img src={smallrect} className='img-fluid rectangle me-2' />
                        <div>
                            <h4 className='fname'>Already You Have Registered Interest</h4>
                           {/* <p className='grayclr smtxt mb-0'>Results will be announced.</p> */}
                            {/*  <p className='grayclr smtxt'>Wednesday, Mar 26,2024</p> */}
                        </div>
                    </div>
                }

                {/* <div>
                    <h4 className='fname'>Swap Progress</h4>
                    <div style={sliderStyles}>
                        <Slider
                            min={0}
                            max={100}
                            value={volumee}
                            onChange={handleOnChange}
                            tooltip={false}
                        />
                        <div className='d-flex justify-content-between'>
                            <p className='whtsclr'>({volumee}%)</p>
                            <p className='whtsclr'>12853 / 19778</p>
                        </div>
                    </div>
                </div> */}



                {/* <p className='fname mt-3'>Time Zone : (GMT -12 : 00)</p> */}

                <div>
                    <div className='d-flex mb-3'>
                        <img src={smallrect} className='img-fluid rectangle me-2' />
                        <div className='upcoming py-3 px-3'>
                            <h5 className='fname'>{salerecords && isSaleLive(salerecords.startTime, salerecords.endTime, salerecords.isPresaleOpen) ?

                                <span className="badge badge_live badge_finish">

                                    <span className='stats_txt'><h5>Sales Live</h5></span>
                                </span> :
                                (salerecords && isUpcoming(salerecords.startTime) ?
                                    <span className="badge  badge_finish">


                                        <span className='stats_txt'><h5>Upcoming</h5></span>
                                    </span> :


                                    <span className="badge badge_finish">

                                        <span className='stats_txt'><h5>Sales Ended</h5></span>
                                    </span>
                                )
                            }</h5>
                            <>
                            <p className='grayclr f-16 mb-2'>Interest Period: </p>
                            <span><span className='grayclr f-16 mb-2'>From</span><span className='whtsclr f-16 ms-3'>{(padToDigits(new Date(parseFloat(salerecords?.regStartTime) * 1000)?.toString().slice(0 , 16)) + "  "
                                + padToDigits(new Date(parseFloat(salerecords?.regStartTime) * 1000).getHours()) + ":" + padToDigits(new Date(parseFloat(salerecords?.regStartTime) * 1000).getMinutes()) + ":"
                                + padToDigits(new Date(parseFloat(salerecords?.regStartTime) * 1000).getSeconds()) + "(GMT)")}</span></span> <br />
                            <span><span className='grayclr f-16 me-2'>To</span><span className='whtsclr f-16 ms-4'>{(padToDigits(new Date(parseFloat(salerecords?.regEndTime) * 1000)?.toString().slice(0 , 16)) + "  "
                                + padToDigits(new Date(parseFloat(salerecords?.regEndTime) * 1000).getHours()) + ":" + padToDigits(new Date(parseFloat(salerecords?.regEndTime) * 1000).getMinutes()) + ":"
                                + padToDigits(new Date(parseFloat(salerecords?.regEndTime) * 1000).getSeconds()) + "(GMT)")}</span></span>
                            {/* <h5 className='fname mt-4'>Token Swap starts in</h5> */}
                            <div className='mt-4'>
                                {salerecords && isUpcoming(salerecords.regStartTime) ?
                                    <p class="mt-2 countup countbtn ml-sm-0 mb-2">
                                        <span class="badge  badge_finish">
                                            <div className='countdown'>
                                                <span className='stats_txt'>Interest Starts in</span> <br /> <Countdown date={Date.now() + (UpcomingDiffernce(salerecords.regStartTime))} renderer={renderer} className="countdown_grey" />
                                            </div>
                                        </span>
                                    </p> :
                                    (salerecords && isSaleLive(salerecords.regStartTime, salerecords.regEndTime, salerecords.isPresaleOpen) ?
                                        <p class="mt-2 countbtn mb-2">
                                            <span class="badge  badge_finish">
                                                {/* <span class=""> */}
                                                    <div className='countdown countdown_grey_cen'>
                                                        <span className='stats_txt'>Interest Ends in</span> <br /> <Countdown date={Date.now() + Salediffernce(salerecords.regEndTime)} renderer={renderer} className="countdown_grey" />
                                                    </div>
                                                </span>
                                        </p> :
                                        <p className='countdown_grey mt-2 countdown_grey_cen'>
                                            <span class="badge  badge_finish">
                                            <span className='stats_txt'>The Interest Is Ended</span> <br/>
                                            </span>
                                        </p>)
                                }</div></>


                            <>
                            <p className='grayclr f-16 mb-2'>Sale Period:</p>
                            <span><span className='grayclr f-16 mb-2'>From</span><span className='whtsclr f-16 ms-3'>{(padToDigits(new Date(parseFloat(salerecords?.startTime) * 1000).toString().slice(0 , 16)) + "  "
                                + padToDigits(new Date(parseFloat(salerecords?.startTime) * 1000).getHours()) + ":" + padToDigits(new Date(parseFloat(salerecords?.startTime) * 1000).getMinutes()) + ":"
                                + padToDigits(new Date(parseFloat(salerecords?.startTime) * 1000).getSeconds()) + "(GMT)")}</span></span> <br />
                            <span><span className='grayclr f-16 me-2'>To</span><span className='whtsclr f-16 ms-4'>{(padToDigits(new Date(parseFloat(salerecords?.endTime) * 1000).toString().slice(0 , 16)) + "  "
                                + padToDigits(new Date(parseFloat(salerecords?.endTime) * 1000).getHours()) + ":" + padToDigits(new Date(parseFloat(salerecords?.endTime) * 1000).getMinutes()) + ":"
                                + padToDigits(new Date(parseFloat(salerecords?.endTime) * 1000).getSeconds()) + "(GMT)")}</span></span>
                            {/* <h5 className='fname mt-4'>Token Swap starts in</h5> */}
                            <div className='mt-4'>
                                {salerecords && isUpcoming(salerecords.startTime) ?
                                    <p class="mt-2 countup countbtn ml-sm-0 mb-2">
                                        <span class="badge  badge_finish">
                                            <div className='countdown'>
                                                <span className='stats_txt'>Sale Starts in</span> <br /> <Countdown date={Date.now() + (UpcomingDiffernce(salerecords.startTime))} renderer={renderer} className="countdown_grey" />
                                            </div>
                                        </span>
                                    </p> :
                                    (salerecords && isSaleLive(salerecords.startTime, salerecords.endTime, salerecords.isPresaleOpen) ?
                                        <p class="mt-2 countbtn mb-2">
                                            <span class="badge  badge_finish">
                                                {/* <span class=""> */}
                                                    <div className='countdown countdown_grey_cen'>
                                                        <span className='stats_txt'>Sale Ends in</span> <br /><br/> <Countdown date={Date.now() + Salediffernce(salerecords.endTime)} renderer={renderer} className="countdown_grey" />
                                                    </div>
                                                </span>
                                        </p> :
                                        <p className='countdown_grey mt-2 countdown_grey_cen'>
                                            <span class="badge  badge_finish">
                                            <span className='stats_txt'>The Sale Is Ended</span> <br />
                                            </span>
                                        </p>)
                                }</div></>
                        </div>
                    </div>
                    
                    <div className='d-flex mb-2'>
                        <img src={smallrect} className='img-fluid rectangle me-2' />
                        <div> <h5 className='fname'>Swap</h5>  <p className='grayclr f-16 mb-2'><span className='whtsclr f-16 ms-3'>{(padToDigits(new Date(parseFloat(salerecords?.startTime) * 1000).toString().slice(0 , 16)) + "  "
                                + padToDigits(new Date(parseFloat(salerecords?.startTime) * 1000).getHours()) + ":" + padToDigits(new Date(parseFloat(salerecords?.startTime) * 1000).getMinutes()) + ":"
                                + padToDigits(new Date(parseFloat(salerecords?.startTime) * 1000).getSeconds()) + "(GMT)")}</span></p></div>
                    </div>

                    <div className='d-flex mb-2'>
                        <img src={smallrect} className='img-fluid rectangle me-2' />
                        <div> <h5 className='fname'>Filled</h5></div>
                    </div>

                    <div className='d-flex mb-2'>
                        <img src={smallrect} className='img-fluid rectangle me-2' />
                        {/* <div> <h5 className='fname'>Claimable amount :</h5> <p className='grayclr f-16 mb-2'>{userdata?.userClaimbale && userdata?.userClaimbale > 0 ? parseFloat(userdata?.userClaimbale)/10**18 + "  "+salerecords?.symbol : 0}</p></div> */}
                    </div>

                    <div className='d-flex mb-2'>
                        <img src={smallrect} className='img-fluid rectangle me-2' />
                        <div> <h5 className='fname'>{salerecords && isSaleLive(salerecords.startTime, salerecords.endTime, salerecords.isPresaleOpen) ?

                            <span className="badge badge_live badge_finish">

                                <span className='stats_txt'><h5>Sales Live</h5></span>
                            </span> :
                            (salerecords && isUpcoming(salerecords.startTime) ?
                                <span className="badge  badge_finish">


                                    <span className='stats_txt'><h5>Upcoming</h5></span>
                                </span> :


                                <span className="badge badge_finish">
                                    <span className='stats_txt'><h5>Sales End</h5></span>
                                </span>
                            )
                        }</h5></div>
                    </div>

                </div>




            </div>

        </div>
    )
}

export default Upcoming