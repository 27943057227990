import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Container, Button, Dropdown } from 'react-bootstrap';
import { GetErc20bal, approveContractbuynative, calculateBuy, calculateNativeBuy, checkIsApproved, getAllocationInfo, toFixedNumber } from '../Hooks/useContract';
import { CHAINS, NATIVETOKEN_SALE } from '../Config/env';
import { getAccount, getChainId } from '../Hooks/useAccounts';
import Schedule from './nativeschedule'
import { BuyNativeToken, GetSalePerAccount, RefundUSDT, claimToken, getSaleInfoCard } from '../Hooks/UseNativesale';
import { iconTheme, position, style } from '../Hooks/useToast';
// import Buyflowmodal from './buyflowmodal';

function Claiminfo(props) {

    const [userdata , setUserdata] = useState();
    const[buysflow,setBuysflow] = useState(true);
    const [Max, setMax] = useState(0);
    const [ismax , setIsmax] = useState(false);
    const [claim , setClaim] = useState(0);
    const [error , setError] = useState("");
    const [approvebutton , setApprovebutton] = useState(false);
    const [allocatedata, setAllocatedata] = useState([]);
    const [saledetail , setSaledetail] = useState({});
    const [usdtbal , setUsdtbal] = useState(0);

    useEffect(() => {
        claimabledata();
        settokenaddress();
    } , [])


    const claimabledata = async () => {
        let result = await getSaleInfoCard(window.location.href?.split("/")[4])
        setSaledetail(result);
        if (localStorage.getItem("accountInfo")) {
            console.log("localStorage", localStorage.getItem("accountInfo"));
            const users = await GetSalePerAccount(localStorage.getItem("accountInfo"), window.location.href?.split("/")[4]);
            console.log("users", users);

            setUserdata(users);

            if (result?.isClaimable) {
                console.log("isClaimable", result?.isClaimable);

                const allocated = await getAllocationInfo(users?.actualBalance, users?.userClaimbale, result?.vestingInterval, result?.vestingPercent, users?.initialClaim , result?.firstClaimPercent);
                console.log("allocatedddd", allocated);
                if(allocatedata?.length <= 0){
                    setAllocatedata(allocated);
                }

            }

            let bal = await GetErc20bal();
            setUsdtbal(bal);
        }
    }

    const settokenaddress = async (value) => {
        // setTokenaddress(value);
        let approv = await checkIsApproved(getAccount() , CHAINS[getChainId()].USDTADDRESS , window.location.href?.split("/")[4])
        setApprovebutton(approv);
    }

    const calculateTokem = async(value) => {
        if(parseFloat(value) > 0){
            const tokenValue = await calculateNativeBuy(window.location.href?.split("/")[4],value);
            setClaim(toFixedNumber(parseFloat(tokenValue)/10**18));
            setError("")
        }
        else{
            // toast.error("Invalid Value")
            setError("Invalid Value")
        }
    }
    const isSaleLive = (start, end, isOpen) => {
        try {
            return (Date.now() >= (start * 1000) && Date.now() <= (end * 1000)) && isOpen;
        }
        catch (e) {
            console.log("isSaleLive", e);
        }
    }
    const Buy = async() => {
        console.log("max" , Max , saledetail?.minEthLimit);
        
        if((parseFloat(saledetail?.minEthLimit) /10**18)<= parseFloat(Max)  && (parseFloat(saledetail?.maxEthLimit)/10**18) >= parseFloat(Max)){
            await BuyNativeToken(window.location.href?.split("/")[4] , Max, false, Max, getAccount(), 18 , "USDT");
            
        }
        else{
            toast.error("You can't buy this limit");
        }
    }

    const approvetoken = async() => {
        // toast.error('Error ! When Buying Token',
        //  {
        //     position:position.position,
        //     style:style,
        //     iconTheme: iconTheme
        // }
        // );
        await approveContractbuynative(localStorage.getItem("accountInfo"),CHAINS[getChainId()].USDTADDRESS,window.location.href?.split("/")[4]);
        setApprovebutton(true);
      }

      const  Claimtoken = async()=>{
        await claimToken(window.location.href?.split("/")[4],getAccount());
        claimabledata();
        settokenaddress();
    }

    const refund = async() => {
        await RefundUSDT(window.location.href?.split("/")[4]);
    }

    return (

        <div>

            <div className='mt-2'>

                {/* <h6 className='fname ms-3'>Please wait until IDO Date & Time at 17:30, 09 Apr 2024 (GMT +05:30)</h6> */}


                <div className='row botline'>
                    <div className='col-xl-6 claimcard mt-4'>
                        <h5 className='fname mb-3'>Swap Tokens</h5>
                        {/* <div className='d-flex justify-content-between'><p className='grayclr'>Softcap</p><p className='whtsclr'>{saledetail && (parseInt(saledetail.softCap)/10**18).toFixed(2)} {props?.buytoken}</p></div> */}
                        <div className='d-flex justify-content-between'><p className='grayclr'>User Invested</p><p className='whtsclr'>{parseFloat(userdata?.userInvested)/10**18} {"  "}{props?.buytoken}</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Refundable Amount</p><p className='whtsclr'>{parseFloat(userdata?.userInvested)/10**18} {"  "}{props?.buytoken}</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Refund</p><p className='whtsclr'>
                            <button  className='maxbtn btn btn-primary'
                            // parseFloat(userdata?.claimEligible)*1000 < Date.now() || parseFloat(userdata?.userInvested) <=  0 || saledetail?.isClaimable
                            disabled = {true}
                            // onClick={() => refund()}
                            >Request To Refund</button>
                            </p></div>
                        {/* <div className='d-flex justify-content-between'><p className='grayclr'>Remaining</p><p className='whtsclr'>0 USDT</p></div> */}
                    </div>
                    <div className='col-xl-6 mt-4'>
                        <div className='swapbalance px-3 py-3'>
                            <div className='d-flex justify-content-between mb-3'><p className='fname mb-0'>Your Wallet Balance</p><p className='grayclr f-16 mb-0'>(Balance: {parseFloat(usdtbal).toFixed(4)} USDT)</p></div>
                            <div className='pos docback'> 
                                <p className='fname max mb-0'>USDT </p>
                                 {/* <Button className='ms-2 maxbtn'>MAX</Button> */}
                                <input type='number' value={Max} className='form-control' 
                                onChange={(e) => {
                                    setMax(e?.target?.value);
                                    calculateTokem(e?.target?.value)
                                }}
                                />
                            </div>
                            <div className='d-flex justify-content-between mt-3'>
                                <div><p className='fname mb-2'>You will get approximately</p></div>
                                <p className='grayclr f-16'>{claim} SPP</p>
                            </div>
                            <p className='fname f-16 mt-3'>You need to Approve first before purchasing. Please set a sufficient spending cap or use the default value.</p>
                            <div className='d-flex align-items-center justify-content-center pt-2'>
                            { approvebutton ? <Button className='themesbtn ms-3' 
                            disabled = {!isSaleLive(saledetail?.startTime, saledetail?.endTime, saledetail?.isPresaleOpen)}
                                    onClick={Buy}
                                    ><span>BUY</span></Button>
                                 : <Button className='themesbtn ms-3' disabled = {!Max}
                                 onClick={approvetoken}
                                 ><span>Approve</span></Button>
                                }
                                    {/* <Button className='themesbtn' disabled><span>Approved</span></Button>
                                    <Button className='themesbtn ms-3' disabled><span>Swap</span></Button> */}
                                </div>
                    
                        </div>
                    </div>
                </div>


                <div className='row mt-3'>
                    <div className='col-xl-6 claimcard mt-4'>
                        <h5 className='fname mb-3'>Claim Token</h5>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Claim Type</p><p className='whtsclr'>{saledetail?.vestingInterval && saledetail?.vestingInterval > 0 ? "Vesting" : "Claim"}</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Claimable Amount</p><p className='whtsclr'>{userdata?.userClaimbale && userdata?.userClaimbale > 0 ? userdata?.userClaimbale/10**18 : 0}</p></div>
                        {/* <div className='d-flex justify-content-between'><p className='grayclr'>Claim Type</p><p className='whtsclr'>Claim On Spropad</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Vesting Schedule</p><p className='whtsclr'>11% TGE</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Total Bought Tokens</p><p className='whtsclr'>12% ABC</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>Have Bought</p><p className='whtsclr'>6.12 USDT</p></div>
                        <div className='d-flex justify-content-between'><p className='grayclr'>You have Claimed</p><p className='whtsclr'>12.33/22.44</p></div> */}
                        {/* <Button className='themesbtn mx-auto'><span>Claim Token</span></Button> */}
                       {saledetail?.vestingInterval && saledetail?.vestingInterval <= 0 &&  <Button  className="themesbtn mx-auto" 
                       
                       onClick={() => Claimtoken()} disabled = {!saledetail?.isClaimable}>Claim</Button>}
                    </div>
                    <div className='col-xl-6 mt-4'>
                        <div>
                            <Schedule data = {parseFloat(userdata?.userInvested)/10**18 > 0 ? allocatedata : []} onclaim = {() => Claimtoken()}/>
                        </div>
                    </div>
                </div>



            </div>

        </div>

    )
}

export default Claiminfo