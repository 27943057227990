import React, { useState, useEffect } from 'react';
import { Container, Button, Dropdown } from 'react-bootstrap';
import Header from '../Layouts/header';
import Footer from '../Layouts/footer';
import Subheaders from "../Layouts/homesubheader";
import grayline from "../images/spropad/grayline.png";
import ellite from "../images/spropad/Elite Pro.png";
import premium from "../images/spropad/Premier Pro.png";
import master from "../images/spropad/Master Pro.png";
import ultimate from "../images/spropad/Ultimate Pro.png";
import doubleline from "../images/spropad/doubleline.png";
import smallrect from "../images/spropad/smallrectangle.png";
import { Link } from 'react-router-dom';
import tick from "../images/spropad/tick.png";
import Names from '../Components/names';
import Profilenav from '../Layouts/profilenav';
import { GetTotalPoints } from '../Hooks/useStake';
import { getAccount } from '../Hooks/useAccounts';
import { GetAllocation } from '../Hooks/UseBackenduser';
import { API_URL } from '../Config/env';

function Mytier() {

    const [activeTab, setActiveTab] = useState('overview');
    const [tablelist, setTablelist] = useState([
        { id: 1, name: "Minimum staking points" },
        { id: 2, name: "Pool Weight / Allocation Multiplier" },
        { id: 3, name: "Social interaction required" },
        { id: 4, name: "FCFS Round" }
    ])

    //dev-murugavel
    const [userpoint , setUserpoint] = useState(0);
    const [allocation , setAllocation] = useState([]);
    const [usertier , setUsertier] = useState("");

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        fetchdata();
    }, [activeTab]);

    const fetchdata = async() => {
        if(getAccount()){
            let points = await GetTotalPoints(getAccount());
            setUserpoint(parseFloat(points)/10**18);
            const Proxydata = await GetAllocation();
            console.log("Proxydata", Proxydata);
            setAllocation(Proxydata?.data?.record);
            Proxydata?.data?.record?.map((data , i) => {
                if(parseFloat(data?.min) <= (parseFloat(points)/10**18)){
                    setUsertier(data?.name)
                }
            })
        }
    }


    return (

        <div className='all'>

            <Header />
            <Subheaders />
            <div className='innerpages pb-5'>
                <div className='container'>
                    <div className='profileback headtext pos'>
                    <Profilenav/>
                        <h1 className='mainhead text-left alltext mb-4'>My <span className='mainsub'> Tier</span></h1>

                        <ul className="tab-list pt-4">
                            <li className={activeTab === 'overview' ? 'active' : 'tabtext'} onClick={() => handleTabClick('overview')}><span>Over View</span></li>
                            <li className={activeTab === 'tierbenefits' ? 'active' : 'tabtext'} onClick={() => handleTabClick('tierbenefits')}><span>Tier Benefits</span></li>
                        </ul>
                        <img src={grayline} className='img-fluid' />

                        {activeTab === 'overview' &&
                            <div className='pt-3'>
                                <h4 className='fname'>Your current tier :  <span className='fw-600 vioclr'>{usertier ? usertier : "you can stake and achieve tier"}</span></h4>
                                <h4 className='vioclr'>Your Tier Points : {userpoint}  Smart Pro  Pad SPP</h4>

                                <div className='d-flex justify-content-between pos elites mb-4'>
                                    <img src={doubleline} className='img-fluid doubleline' />
                                    {allocation?.map((data , i) => <div className='mt-3'>
                                        <img src={API_URL + data?.image} className='img-fluid proimg' />
                                        <h5 className='fname'>{data?.name}<br className='d-block d-sm-none'/> </h5>
                                        {/* <p className='grayclr mb-0'>{data?.min} Smart Pro Pad SPP</p> */}
                                    </div>)}
                                    

                                    {/* <div className='mt-3'>
                                        <img src={premium} className='img-fluid proimg' />
                                        <h5 className='fname'>Premier<br className='d-block d-sm-none'/> Pro</h5>
                                        <p className='grayclr mb-0'>0 spro pad sp</p>
                                    </div>
                                    <div className='mt-3'>
                                        <img src={master} className='img-fluid proimg' />
                                        <h5 className='fname'>Master<br className='d-block d-sm-none'/> Pro</h5>
                                        <p className='grayclr mb-0'>0 spro pad sp</p>
                                    </div>
                                    <div className='mt-3'>
                                        <img src={ultimate} className='img-fluid proimg' />
                                        <h5 className='fname'> Ultimate<br className='d-block d-sm-none'/> Pro</h5>
                                        <p className='grayclr mb-0'>0 spro pad sp</p>
                                    </div> */}
                                </div>

                                <div className='pt-2 d-block d-md-flex align-items-center'>
                                    <h5 className='fname mt-3 me-3'>{usertier ? usertier : "you can stake and achieve tier"}</h5>
                                   <Button className='btn viewspro claimbtn mt-4' onClick={() => handleTabClick('tierbenefits')}><span className='ctext'>Smart Pro Pad tiers &#8594;</span></Button>
                                </div>

                                <div className='pt-5'>
                                    <h3 className='fname'>Staking Information</h3>
                                    <p className='fname'>you can stake $SPP to achieve smart pro pad SPP</p>
                                   <Link to="/staking"><Button className='themesbtn mt-4'><span>Stake now</span></Button></Link>
                                    <img src={grayline} className='img-fluid mt-4' />
                                </div>

                                <div className='pt-4'>
                                    <h4 className='fname fw-400'>Learn more about how to acheive Smart Pro Pad tier here :</h4>

                                    <div className='row questions'>
                                        <div className='col-md-5 mt-3'>
                                            <Link to="/" target="_blank"><h5 className='fname fw-400 d-flex align-items-center'><img src={smallrect} className='img-fluid me-2' /> How to stake?</h5></Link>
                                        </div>
                                        <div className='col-md-5 mt-3'>
                                            <Link to="/" target="_blank"><h5 className='fname fw-400 d-flex align-items-center'><img src={smallrect} className='img-fluid me-2' /> What are SPP Staking Points (Smart Pro Pad SPP)?</h5></Link>
                                        </div>
                                        <div className='col-md-5 mt-3'>
                                            <Link to="/" target="_blank"> <h5 className='fname fw-400 d-flex align-items-center'><img src={smallrect} className='img-fluid me-2' /> what is Smart Pro Pad tiers?</h5></Link>
                                        </div>
                                        <div className='col-md-5 mt-3'>
                                            <Link to="/" target="_blank"><h5 className='fname fw-400 d-flex align-items-center'><img src={smallrect} className='img-fluid me-2' /> my tier faqs</h5></Link>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        }

                        {activeTab === 'tierbenefits' &&
                            <div className='pt-3 allbenefits'>
                                <div className='d-none d-md-block'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <p className='grayclr'>Tier Benefits</p>
                                    </div>
                                    <div className='col-md-8'>
                                        <div className='row'>
                                            {allocation?.map((data , i) => <div className='col-md-3'>
                                                <img src={API_URL + data?.image} className='img-fluid imgpros' />
                                                <p className='grayclr'>{data?.name}</p>
                                            </div>)}
                                            
                                            {/* <div className='col-md-3'>
                                                <img src={premium} className='img-fluid imgpros' />
                                                <p className='grayclr'>Premier Pro</p>
                                            </div>
                                            <div className='col-md-3'>
                                                <img src={master} className='img-fluid imgpros' />
                                                <p className='grayclr'>Master Pro</p>
                                            </div>
                                            <div className='col-md-3'>
                                                <img src={ultimate} className='img-fluid imgpros' />
                                                <p className='grayclr'>Ultimate Pro</p>
                                            </div> */}
                                        </div>
                                    </div>
                                </div></div>

                                <div className='gridtable py-4 px-2 px-sm-3 mb-3'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <h5 className='fname mb-0'>Minimum staking points</h5>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='row'>
                                             <Names/> 
                                             {allocation?.map((data , i) =><div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>{">" + data?.min+ "+"}</h5>
                                                </div>)}
                                                
                                                {/* <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>2,000</h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>50,000</h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>200,000</h5>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='gridtable py-4 px-2 px-sm-3 mb-3'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <h5 className='fname mb-0'>Tier Allocation</h5>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='row'>
                                              <Names/> 
                                              {allocation?.map((data , i) =><div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>{data?.percentage} %</h5>
                                                </div>)}
                                                
                                                {/* <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>4x</h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>10x</h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>40x</h5>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                          
                                <div className='gridtable py-4 px-2 px-sm-3 mb-3'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <h5 className='fname mb-0'>Pool Weight / Allocation Multiplier</h5>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='row'>
                                              <Names/> 
                                              {allocation?.map((data , i) =><div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>{data?.multiplier}x</h5>
                                                </div>)}
                                                
                                                {/* <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>4x</h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>10x</h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>40x</h5>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className='gridtable py-4 px-2 px-sm-3 mb-3'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <h5 className='fname mb-0'>Social interaction required</h5>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='row'>
                                                <Names/> 
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>2,000</h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>-</h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>-</h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>-</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className='gridtable py-4 px-2 px-sm-3 mb-3'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <h5 className='fname mb-0'>FCFS Round</h5>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='row'>
                                            <Names/> 
                                            {allocation?.map((data , i) =><div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'><img src={tick} className='img-fluid'/></h5>
                                                </div>)}
                                                {/* <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'><img src={tick} className='img-fluid'/></h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'><img src={tick} className='img-fluid'/></h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'><img src={tick} className='img-fluid'/></h5>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='gridtable py-4 px-2 px-sm-3 mb-3'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <h5 className='fname mb-0'>Multivendor Round</h5>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='row'>
                                            <Names/> 
                                            
                                            {allocation?.map((data , i) => <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>{<img src={tick} className='img-fluid'/>}</h5>
                                                </div>)}

                                                {/* <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'><img src={tick} className='img-fluid'/></h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'><img src={tick} className='img-fluid'/></h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'><img src={tick} className='img-fluid'/></h5>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className='gridtable py-4 px-2 px-sm-3 mb-3'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <h5 className='fname mb-0'>PrivateSale Round</h5>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='row'>
                                            <Names/> 
                                            
                                            {allocation?.map((data , i) => <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'>{(data?.name == "Ultimate Pro" || data?.name == "Master Pro") ? <img src={tick} className='img-fluid'/> : "-"}</h5>
                                                </div>)}

                                                {/* <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'><img src={tick} className='img-fluid'/></h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'><img src={tick} className='img-fluid'/></h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'><img src={tick} className='img-fluid'/></h5>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='gridtable py-4 px-2 px-sm-3 mb-3'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <h5 className='fname mb-0'>Refunding</h5>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='row'>
                                            <Names/> 
                                            
                                            {allocation?.map((data , i) => <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'><img src={tick} className='img-fluid'/></h5>
                                                </div>)}

                                                {/* <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'><img src={tick} className='img-fluid'/></h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'><img src={tick} className='img-fluid'/></h5>
                                                </div>
                                                <div className='col-3 col-sm-3 col-md-3 mt-2 mt-md-0'>
                                                <h5 className='fname mb-0'><img src={tick} className='img-fluid'/></h5>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        }


                    </div>

                    <p className='fname my-5 mx-2 mx-sm-4 fw-300'>Disclaimer :  the information provided shall not in any way constitute a recommendation as to whether you should invest in any product discussed. we accept no liability for any loss accessioned  to any person acting     or refraining from action as a result of any material provided or published. </p>

                </div>
            </div>
            <Footer />
        </div>

    )
}

export default Mytier;