import { LAUNCHPAD_MASTERCHEF_ABI } from "../ABI/masterchefAbi";
import { ERC20_ABI } from "../ABI/erc20";
import toast, { Toaster } from 'react-hot-toast';
import { style, iconTheme, position } from './useToast'
import { GetChainIndex, useWeb3, usehttpProvider } from "./UseWeb3"
import { CHAINS, NATIVETOKEN_SALE } from "../Config/env";
import BigNumber from 'bignumber.js';
import PROXY_SALE_ABI from '../ABI/PROXY_ABI.json'
import contractAddress, { PROXY,XLAUNCH } from "../constant/contract";
import SALE_ABI from '../ABI/SALE_ABI.json'
import { UserBuyHooks } from "./UseBackenduser";
// import { NATIVE_SALE_ABI } from "../ABI/NativesaleAbi";
import { getAccount } from "./useAccounts";
import { NATIVE_SALE_ABI } from "../ABI/NativeProxy";

export const IsValidAddress = async (ADDRESS) => {
    try {
        const web3 = await useWeb3();
        const value = await web3.utils.isAddress(ADDRESS);
        return value;
    }
    catch (err) {
        console.log("IsValidAddress", err.code)
    }
}

export const UseMasterChef = async (VALUE) => {
    try {
        const web3 = await useWeb3();

        const contract = new web3.eth.Contract(LAUNCHPAD_MASTERCHEF_ABI, VALUE);
        return contract;
    }
    catch (e) {
        console.log("error", e);
    }
}


export const UseERC20 = async (VALUE) => {
    console.log("userrr20",VALUE);
    try {
        const web3 = await useWeb3();
        const Tokencontract = new web3.eth.Contract(ERC20_ABI, VALUE);
        return Tokencontract;
    } catch (e) {

    }
}


//new
export const UseTokenInfo = async (TOKEN) => {
    console.log("tokensss",TOKEN);
    try {
        console.log("token", TOKEN);
        const token = await UseERC20(TOKEN);
        const name = await token.methods?.name().call(); console.log("name", name);
        const symbol = await token.methods?.symbol().call();
        const decimals = await token.methods?.decimals().call();
        const totalSupply = await token.methods?.totalSupply().call();
        const data = {
            name: name,
            symbol: symbol,
            decimals: decimals,
            totalSupply: totalSupply
        }
        console.log("data", data);
        return data;
    }
    catch (err) {
        console.log("UseTokenInfo", err.code)
    }
}

export const getUSDTAddress = () => {
    try {
        const index = GetChainIndex();
        const ContractAddress = CHAINS[index].USDTADDRESS;

        return ContractAddress;
    } catch (e) {

    }
}


export const UsegetSaleInfo = async (presaleAddress, account) => {
    
    try {
        const web3 = await useWeb3();
        const saleContract = await UseMasterChef(presaleAddress);
        const usdtaddress = await getUSDTAddress()
        const presaletoken = await saleContract.methods.tokenAddress().call();
        const presaletokenContract = await UseERC20(presaletoken);
        const erc20Contract = await UseERC20(usdtaddress);
        const UserusdttokenBalance = await erc20Contract.methods.balanceOf(account).call();
        const presaletokensymbol = await presaletokenContract.methods.symbol().call();
        const tokenpricePer = await saleContract.methods.tokenRatePerEth().call();

        const minamount = await saleContract.methods.minEthLimit().call() / 10 ** 18;
        const maxamount = await saleContract.methods.maxEthLimit().call() / 10 ** 18;
        const pricePerCrypto = await saleContract.methods.tokenRatePercrypto().call();
        const busdminamount = await saleContract.methods.BUSDminEthLimit().call() / 10 ** 18;
        const busdmaxamount = await saleContract.methods.BUSDmaxEthLimit().call() / 10 ** 18;
        const bnbearnedcap = await saleContract.methods.earnedCap().call();
        const bnbearnedcapRoundValue = UseRoundValue(bnbearnedcap, 18)
        const busdearnedcap = await saleContract.methods.BUSDearnedCap().call();
        const busdearnedcaproundvalue = UseRoundValue(busdearnedcap, 18);
        const allowance = await erc20Contract.methods.allowance(account, presaleAddress).call();
        const investedT = await saleContract.methods.getUserInvestments(account).call() / 10 ** 18;
        const busdinvestedT = await saleContract.methods.getUserBUSDInvestments(account).call() / 10 ** 18;
        const UserBNB = await web3.eth.getBalance(account) / 10 ** 18;
       
        //  const busdbalance = await erc20Token.methods.balanceOf(ADDRESS).call() / 10 ** 18;
        const claimb = await saleContract.methods.getUserClaimbale(account).call() / 10 ** 18;
        const soldT = await saleContract.methods.soldTokens().call() / 10 ** 18;

        const ispresaleopen = await saleContract.methods.isPresaleOpen().call();
        const isclaimable = await saleContract.methods.isClaimable().call();
        const hard = await saleContract.methods.hardCap().call();
        const bnbroundvalue = UseRoundValue(hard, 18)
        const busdhard = await saleContract.methods.BUSDhardCap().call();
        const busdhardroundvalue = UseRoundValue(busdhard, 18);
        const endD = await saleContract.methods.endTime().call();
        const date = new Date(parseInt(endD) * 1000);
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const saleEndTime = date.toLocaleDateString("en-US", options);

        const data = {
            pricePer: tokenpricePer,
            minamount: minamount,
            maxamount: maxamount,
            pricePerCrypto: pricePerCrypto,
            busdminamount: busdminamount,
            busdmaxamount: busdmaxamount,
            bnbearnedcap: bnbearnedcapRoundValue.toString(),
            busdearnedcap: busdearnedcaproundvalue.toString(),
            investedT:investedT,
            busdinvestedT:busdinvestedT,
            claimb: claimb.toString(),
            soldT: soldT,
            saleEndTime: saleEndTime,
            ispresaleopen: ispresaleopen,
            hardCap: bnbroundvalue,
            busdhard: busdhardroundvalue.toString(),
            isclaimable: isclaimable,
            usdtaddress: usdtaddress,
            UserusdttokenBalance: UserusdttokenBalance,
            presaletoken: presaletoken,
            UserBNB: UserBNB,
            allowance:allowance,
            presaletokensymbol:presaletokensymbol
        }
        return data;
    }
    catch (e) {
        console.log("UsegetSaleInfo", e);
    }
}




export const UseBuyToken = async (coinname, useramount, token, presaleAddress, account,isMax) => {
        const web3 = await useWeb3();
       
        const erc20Contract = await UseERC20(token); 
        const symbol = await erc20Contract.methods.symbol().call();

        const saleContract = await UseMasterChef(presaleAddress);
        // const value = parseFloat(useramount)
        // const amount = parseFloat(useramount * 10 ** 18)
        const amount = !isMax? toFixedNumber(useramount * 10 **18): useramount ;
        // const amount = new BigNumber(value).times(new BigNumber(10).pow(18));
        console.log("amount",amount);
        // if (coinname === 'BNB') {
            
        // const data = await web3.eth.sendTransaction({ from: account, to: presaleAddress, value: amount.toString() })
        //        await toast.promise(data, {
        //         loading: `Buying ${symbol} token...`,
        //         success: 'Buy Successfully',
        //         error: 'Try Again',
        //     }, {
        //         position: position.position,
        //         style: style,
        //         iconTheme: iconTheme,
        //     })
        //      }
            //  else {
                
               

                 const data =  saleContract.methods.buyToken(amount.toString()).send({ from: account })
                 await toast.promise(data, {
                    loading: `Buying ${symbol} token...`,
                    success: 'Buy Successfully',
                    error: 'Try Again',
                }, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })

// }
        
   
}
export const sleep = (ms) => {
    new Promise(resolve => setTimeout(resolve, ms))
}

export const approveContract = async (account, token, presaleaddress) => {
try {
    const erc20Contract = await UseERC20(token);
    const symbol = await erc20Contract.methods.symbol().call();
    const data = erc20Contract.methods.approve(presaleaddress, "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account })
    console.log("Datas",data)
    await toast.promise(data, {
        loading: `Approving ${symbol} token...`,
        success: 'Approved Successfully',
        error: 'Try Again',
    }, {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
    })
} catch (error) {
    console.log("error",error);
}
    
}

export const GetErc20bal = async() => {
    try{
        const erc20Contract = await UseERC20(CHAINS[0]?.USDTADDRESS);
        const data = await erc20Contract.methods.balanceOf(getAccount()).call();
        return (parseFloat(data)/10**18).toFixed(4);
    }
    catch(e){
        console.log("error on get erc20bla"  , e);
    }
}




export const Claim = async (account, presaleAddress) => {

    const saleContract = await UseMasterChef(presaleAddress);
    const data = saleContract.methods.claimTokens().send({ from: account });
    await toast.promise(data, {
        loading: 'Requesting for Claim Tokens...',
        success: 'Tokens Claimed Successfully',
        error: 'Error ! When Claiming Token',
    }, {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
    }
    );
}
export const GetWhitelistedAmount = async(saleaddress) => {
    try{
        const saleContact = await UseSale(saleaddress);
        console.log("saleContract.methods" , saleContact.methods);
        const data = await saleContact.methods.getUserWhitelistAmount(getAccount()).call();
        console.log("data in whitelist" , data);
        return parseFloat(data)/10**18;
    }
    catch(e){
        console.log("Error on get whitelisted amount" , e);
    }
}

export const toFixedNumber = (x) => {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}



export const checkIsApproved = async (account, token, presaleAddress) => {
    try {

        const erc20Contract = await UseERC20(token);
        const allow = await erc20Contract.methods.allowance(account, presaleAddress).call();


        return (parseInt(allow) > 0) ? true : false;
    } catch (e) {

    }
}

export const UsergetToken = async (amount, coinname, token, presaleAddress) => {
    console.log("amountSDDFFDFDF",amount, coinname, token, presaleAddress);

    if (amount !== null && amount > 0 && amount !== "") {

        const saleContract = await UseMasterChef(presaleAddress);
        const erc20Contract = await UseERC20(token);
        let price = 0;
        const decimals = await erc20Contract.methods.decimals().call();

        if (coinname === "BNB") {
            const price = await saleContract.methods.getTokensPerEth(new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()).call() / 10 ** parseInt(decimals);

            return price;
        } else {

            const price = await saleContract.methods.getTokenPerCrypto(new BigNumber(amount).times(new BigNumber(10).pow(18)).toString()).call() / 10 ** parseInt(decimals);
            return price;

        }
    }
}






export const searchedValue = async (ProposalList, searchSymbol) => {
    const filteredData = await ProposalList.filter(value => ((value.isActive == searchSymbol)));
    return filteredData;
}


export const searchedCloseProposal = async (ProposalList) => {
    const filteredData = await ProposalList.filter(value => ((!value.isActive === !value.status)));
    return filteredData;
}

const UseRoundValue = (VALUE, DECIMALS) => {
    return VALUE > 0 ? Math.round((new BigNumber(VALUE).div(new BigNumber(10).pow(DECIMALS))).toNumber()) : new BigNumber(0);

}


export const UsegetsingleSaleInfo = async (presaleAddress, account) => {
    
    try {
        const web3 = await useWeb3();
        const saleContract = await UseMasterChef(presaleAddress);
        
        const pricePerCrypto = await saleContract.methods.tokenRatePercrypto().call();
     

        const ispresaleopen = await saleContract.methods.isPresaleOpen().call();
        

        const data = {
            
            pricePerCrypto: pricePerCrypto,
           ispresaleopen: ispresaleopen,
            

        }

        return data;
    }
    catch (e) {
        console.log("UsegetSaleInfo", e);
    }
}

var CheckToast = true


export const CURRENT_CHAIN_ID = () => {
    const index = GetChainIndex()
    console.log("index>>>>", index);
    return CHAINS[index].CHAIN_ID;
  }

export const checkChainId = (chainId) => {
    try {
        const CHAIN_ID = CURRENT_CHAIN_ID();
        if (parseInt(chainId) != parseInt(CHAIN_ID)) {
            if (CheckToast) {

                toast.error(`Connected to Wrong Network !`,
                    // {
                    //     position: position.position,
                    //     style: style,
                    //     iconTheme: iconTheme
                    // }
                    )
            }
        }
        CheckToast = false
        return true;
    }
    catch (err) {
        console.log("checkChainId", err.code)
    }
}

export const getProxyAddress = () => {
    try {
        const CHAIN_ID = CURRENT_CHAIN_ID();
        return PROXY[CHAIN_ID];
    }
    catch (err) {
        console.log("getProxyAddress", err.code,err)
    }
}

export const UseProxySale = async () => {
    try {
        const web3 = await useWeb3();
        const chainId = await web3.eth.getChainId();
        checkChainId(chainId);
        const proxysale = getProxyAddress();

        const contract = new web3.eth.Contract(PROXY_SALE_ABI, proxysale);
        console.log("proxysale", contract);
        return contract;
    }
    catch (err) {
        console.log("UseProxySale", err.code,err)
    }
}

export const getTotalSalesInfo = async () => {
    try {
        const proxy = await UseProxySale();

        let data = await proxy.methods.getTotalSales().call();
        console.log("proxy inside getTotalSales info:", data);
        // const initial = [{
        //     _sale  : "0xA802274ba245171A37d39beC1Fd01cF459A8aeA8",
        //     _start : 142108977862384,
        //     _end : 142116442822384,
        //     _name : "Metaverse Lab"
        // }];
        // data = (initial).concat(data);

        const reverseArray = [...data].reverse();
        return (reverseArray);
    }
    catch (e) {
        console.log("getTotalSalesInfo", e);
    }
}


export const UseSale = async (VALUE) => {
    console.log("VALUE",VALUE);
    try {
        const web3 = await useWeb3();
        const contract = new web3.eth.Contract(SALE_ABI, VALUE);
        return contract;
    }
    catch (err) {
        console.log("UseSale", err.code)
    }
}


export const getSaleInfo = async (ADDRESS) => {
    try {
        console.log("ADDRESS",ADDRESS);
        
        const saleContract = await UseSale(ADDRESS);
        console.log("ssaleContract",saleContract);

        const tokenAddress = await saleContract?.methods?.tokenAddress().call();
        console.log("saleContract>>>",saleContract.methods,tokenAddress);
        const isWithoutToken = await saleContract?.methods?.isWithoutToken().call();
        console.log("isWithoutToken",isWithoutToken);

        let name, symbol, decimals = "";
        if (isWithoutToken) {
            name = await saleContract.methods.tokenName().call();
            symbol = await saleContract.methods.tokenSymbol().call();
            decimals = await saleContract.methods.tokenDecimals().call();
        } else {
            const tokenContract = await UseERC20(tokenAddress);
            name = await tokenContract.methods.name().call();
            symbol = await tokenContract.methods.symbol().call();
            decimals = await tokenContract.methods.decimals().call()
        }
        const description = await saleContract.methods.description().call();
        const logo = await saleContract.methods.logo().call();
        const start = await saleContract.methods.startTime().call();
        const end = await saleContract.methods.endTime().call();
        const islive = Date.now() >= start && Date.now <= end;
        const earnedCap = await saleContract.methods.earnedCap().call();
        const participants = await saleContract.methods.participants().call();
        const website = await saleContract.methods.social(0).call();
        const twitter = await saleContract.methods.social(1).call();
        const telegram = await saleContract.methods.social(2).call();
        const githup = await saleContract.methods.social(5).call();
        const instagram = await saleContract.methods.social(6).call();
        const discord = await saleContract.methods.social(7).call();
        const reddit = await saleContract.methods.social(8).call();
        const youtube = await saleContract.methods.social(9).call();
        const isPancake = await saleContract.methods.isautoAdd().call();
        const isVested = await saleContract.methods.isVested().call();
        const vestingInterval = await saleContract.methods.vestingInterval().call();
        const vestingPercent = await saleContract.methods.vestingPercent().call();
        const hardCap = await saleContract.methods.hardCap().call();
        const softCap = await saleContract.methods.softCap().call();
        const tokenRatePerEth = await saleContract.methods.tokenRatePerEth().call();
        const minEthLimit = await saleContract.methods.minEthLimit().call();
        const maxEthLimit = await saleContract.methods.maxEthLimit().call();
        const ownerAddress = await saleContract.methods.owner().call();
        const data = {
            name: name,
            symbol: symbol,
            decimals: decimals,
            tokenAddress: tokenAddress,
            logo: logo,
            isWithoutToken: isWithoutToken,
            description: description,
            start: start,
            end: end,
            islive: islive,
            earnedCap: earnedCap,
            participants: participants,
            website: website,
            twitter: twitter,
            telegram: telegram,
            isPancake: isPancake,
            isVested: isVested,
            vestingInterval: vestingInterval,
            vestingPercent: vestingPercent,
            hardCap: hardCap,
            softCap: softCap,
            tokenRatePerEth: tokenRatePerEth,
            minEthLimit: minEthLimit,
            maxEthLimit: maxEthLimit,
            owner: ownerAddress,
            githup: githup,
            instagram: instagram,
            discord: discord,
            reddit: reddit,
            youtube: youtube,

        }
        console.log("owner>>>", data,data.owner);
        return data;
    }
    catch (err) {
        console.log("getSaleInfoError", err.code)
    }
}


export const getSaleInfoCard = async (saleAddress) => {
    try {
        console.log(saleAddress,'saleAddress')
        const saleContract = await UseSale(saleAddress);
        const data = await saleContract.methods.getSaleInfo().call();
        console.log("new", data);
        return data;
    }
    catch (e) {
        console.log("getSaleInfoCard_err", e)
    }

}

export const checkbalnceTokens = async (tokenAddress, saleAddress) => {
    try {
        const erc20 = await UseERC20(tokenAddress)
        const balance = await erc20.methods.balanceOf(saleAddress).call();
        return balance;
    }
    catch (err) {
        console.log("checkbalnceTokens", err.code)
    }
}

//new
export const GetSalePerAccount = async (account, saleAddress) => {
    console.log("User_data " ,account, saleAddress )
    try {
        const saleContract = await UseSale(saleAddress);
        const userData = await saleContract.methods.getUserInfo(account).call();
        return userData;
    }
    catch (err) {
        console.log("GetSalePerAccount", err.code)
    }
}

export const getAllocationInfo = (actualBalance,userBalance,vestingInterval,vestingPercentage,initalClaimed , firstclaimpercent) => {
    console.log("Vesting Balance : ",actualBalance,vestingInterval,vestingPercentage,initalClaimed  , userBalance , firstclaimpercent)
    //    debugger
        // let vestingCount = parseInt(100 / (parseInt(vestingPercentage)/100));
        let vestingCount = parseInt((100-(parseInt(firstclaimpercent)/100)) / (parseInt(vestingPercentage)/100));
        const vestingBalance = parseInt(actualBalance) - ((parseInt(actualBalance * vestingPercentage/10000)*(vestingCount-1)) + parseInt(actualBalance * firstclaimpercent/10000))
        console.log("Vesting Balance counyt :",vestingCount,vestingBalance)
        if(initalClaimed<=0)
            initalClaimed = Date.now()/1000;
        
        let allocations = [];   
        // actualBalance = actualBalance - (parseInt(actualBalance * firstclaimpercent/10000)*10**18)
        let inidata = {
            no: 1,
            allocation: parseInt(actualBalance * firstclaimpercent/10000),
            unlockon:  initalClaimed ,
            isClaimed: (initalClaimed*1000 == Date.now()),
            isDid: (parseInt(actualBalance * firstclaimpercent/10000)  <= (actualBalance - userBalance)) 
        }
        allocations.push(inidata);
        for(var i=1;i<vestingCount; i++){
            const data = {
                no: i+1,
                allocation: parseInt(actualBalance * vestingPercentage/10000),
                unlockon: (i==0) ? initalClaimed : parseInt(initalClaimed)+ (vestingInterval*(i)),
                isClaimed: (i==0) ? (initalClaimed*1000 == Date.now()) : ((Date.now() >= parseInt(parseInt(initalClaimed)+ (vestingInterval*(i)))*1000)),
                isDid: (i==0) ? (parseInt(actualBalance * vestingPercentage/10000)  <= (actualBalance - userBalance)) : ((parseInt(actualBalance * vestingPercentage/10000)*(i) + parseInt(actualBalance * firstclaimpercent/10000))  <= (actualBalance - userBalance))
            };
          //  console.log("Alocation data : ",data.isDid,(parseInt(actualBalance * vestingPercentage/10000)*i , (actualBalance - userBalance)))
            allocations.push(data);
        }
        if(vestingBalance > 0){
            const data = {
                no: (allocations.length)+1,
                allocation: vestingBalance,
                unlockon: initalClaimed+ (vestingInterval*(allocations.length)+1),
                isClaimed: (Date.now() >= parseInt(initalClaimed+ (vestingInterval*(allocations.length)+1))*1000),
                isDid: (parseInt(actualBalance * vestingPercentage/10000)*(allocations.length)+1)  <= (actualBalance - userBalance)
            };
            allocations.push(data);
        }
    
        console.log("Allocation : ",allocations)
        return allocations;
}


export const getPresaleAllocationInfo = (actualBalance,userBalance,vestingInterval,vestingPercentage,initalClaimed , firstclaimpercent) => {
//     console.log("Vesting Balance : ",actualBalance,vestingInterval,vestingPercentage,initalClaimed)
// //    debugger
//     let vestingCount = parseInt((100-(parseInt(firstclaimpercent)/100)) / (parseInt(vestingPercentage)/100));
//     const vestingBalance = parseInt(actualBalance) - (parseInt(actualBalance * vestingPercentage/10000)*vestingCount)
//     console.log("Vesting Balance counyt :",vestingCount,vestingBalance)
//     if(initalClaimed<=0)
//         initalClaimed = Date.now()/1000;
    
//     let allocations = [];  
//     let inidata = {
//         no: 1,
//         allocation: parseInt(actualBalance * firstclaimpercent/10000),
//         unlockon:  initalClaimed ,
//         isClaimed: (initalClaimed*1000 == Date.now()),
//         isDid: (parseInt(actualBalance * firstclaimpercent/10000)  <= (actualBalance - userBalance)) 
//     }
//     allocations.push(inidata);
//     for(var i=1;i<vestingCount; i++){
//         const data = {
//             no: i+1,
//             allocation: parseInt(actualBalance * vestingPercentage/10000),
//             unlockon: (i==0) ? initalClaimed : parseInt(initalClaimed)+ (vestingInterval*(i)),
//             isClaimed: (i==0) ? (initalClaimed*1000 == Date.now()) : ((Date.now() >= parseInt(parseInt(initalClaimed)+ (vestingInterval*(i)))*1000)),
//             isDid: (i==0) ? (parseInt(actualBalance * vestingPercentage/10000)  <= (actualBalance - userBalance)) : (parseInt(actualBalance * vestingPercentage/10000)*(i+1)  <= (actualBalance - userBalance))
//         };
//       //  console.log("Alocation data : ",data.isDid,(parseInt(actualBalance * vestingPercentage/10000)*i , (actualBalance - userBalance)))
//         allocations.push(data);
//     }
//     if(vestingBalance > 0){
//         const data = {
//             no: (allocations.length)+1,
//             allocation: vestingBalance,
//             unlockon: initalClaimed+ (vestingInterval*(allocations.length)+1),
//             isClaimed: (Date.now() >= parseInt(initalClaimed+ (vestingInterval*(allocations.length)+1))*1000),
//             isDid: (parseInt(actualBalance * vestingPercentage/10000)*(allocations.length)+1)  <= (actualBalance - userBalance)
//         };
//         allocations.push(data);
//     }

//     console.log("Allocation : ",allocations)
//     return allocations;

console.log("Vesting Balance : ",actualBalance,vestingInterval,vestingPercentage,initalClaimed)
//    debugger
    // let vestingCount = parseInt(100 / (parseInt(vestingPercentage)/100));
    let vestingCount = parseInt((100-(parseInt(firstclaimpercent)/100)) / (parseInt(vestingPercentage)/100));
    const vestingBalance = parseInt(actualBalance) - ((parseInt(actualBalance * vestingPercentage/10000)*(vestingCount-1)) + parseInt(actualBalance * firstclaimpercent/10000))
    console.log("Vesting Balance counyt :",vestingCount,vestingBalance)
    if(initalClaimed<=0)
        initalClaimed = Date.now()/1000;
    
    let allocations = [];   
    // actualBalance = actualBalance - (parseInt(actualBalance * firstclaimpercent/10000)*10**18)
    let inidata = {
        no: 1,
        allocation: parseInt(actualBalance * firstclaimpercent/10000),
        unlockon:  initalClaimed ,
        isClaimed: (initalClaimed*1000 == Date.now()),
        isDid: (parseInt(actualBalance * firstclaimpercent/10000)  <= (actualBalance - userBalance)) 
    }
    allocations.push(inidata);
    for(var i=1;i<vestingCount; i++){
        const data = {
            no: i+1,
            allocation: parseInt(actualBalance * vestingPercentage/10000),
            unlockon: (i==0) ? initalClaimed : parseInt(initalClaimed)+ (vestingInterval*(i)),
            isClaimed: (i==0) ? (initalClaimed*1000 == Date.now()) : ((Date.now() >= parseInt(parseInt(initalClaimed)+ (vestingInterval*(i)))*1000)),
            isDid: (i==0) ? (parseInt(actualBalance * vestingPercentage/10000)  <= (actualBalance - userBalance)) : ((parseInt(actualBalance * vestingPercentage/10000)*(i) + parseInt(actualBalance * firstclaimpercent/10000))  <= (actualBalance - userBalance))
        };
      //  console.log("Alocation data : ",data.isDid,(parseInt(actualBalance * vestingPercentage/10000)*i , (actualBalance - userBalance)))
        allocations.push(data);
    }
    if(vestingBalance > 0){
        const data = {
            no: (allocations.length)+1,
            allocation: vestingBalance,
            unlockon: initalClaimed+ (vestingInterval*(allocations.length)+1),
            isClaimed: (Date.now() >= parseInt(initalClaimed+ (vestingInterval*(allocations.length)+1))*1000),
            isDid: (parseInt(actualBalance * vestingPercentage/10000)*(allocations.length)+1)  <= (actualBalance - userBalance)
        };
        allocations.push(data);
    }

    console.log("Allocation : ",allocations)
    return allocations;
}

export const getlaunchpaddatalimit = async (cards, start, end) => {
    try {
        let SaleInfoCards = [];
        console.log("card", cards, start, end, cards.length);
        if (cards.length > 0 && cards.length > start) {
            for (var i = start; i < end; i++) {
                console.log("forlop", i);
                if (cards.length > i) {
                    console.log("card[i]", cards[i], i);
                    if (cards[i]?.isdb == true || cards[i]?.isdb == "true") {
                        SaleInfoCards.push(cards[i]);
                    }
                    else {
                        var saleInfo = await getSaleInfoCard(cards[i]._sale ? cards[i]._sale : cards[i].saleaddress);
                        
                        console.log("saleInfo2", saleInfo)
                        if (saleInfo?.useWithToken == "0x0000000000000000000000000000000000000000") {
                            saleInfo = { ...saleInfo, ...{ coin: "BNB" } };
                            console.log("saleInfo_saleInfo", saleInfo)

                            SaleInfoCards.push(saleInfo);
                            console.log("bnb", SaleInfoCards);
                        }
                        else {
                            const newdata = await UseTokenInfo(saleInfo.useWithToken);
                            saleInfo = { ...saleInfo, ...{ coin: newdata.symbol } };
                            SaleInfoCards.push(saleInfo);
                        }
                    }
                }
                else {
                    console.log("other", SaleInfoCards);
                    return SaleInfoCards;
                }
            }
        }
        console.log("other", SaleInfoCards);
        return SaleInfoCards;
    } catch (err) {
        console.log(err, "getlaunchpaddatalimit__err")
    }
}



export const GetLaunchpadLimit = async(cards , start , end) => {
    // debugger
    try{console.log("launchpad limit" , cards , start , end);
        var SaleInfoCards = [];
        if (cards.length > 0 && cards.length > start){
            for (var i = start ; i < end; i++){
                console.log("DA sale ingo start" , cards[i]?._sale , cards[i]);
                if (cards.length > i){
                    var saleInfo = await getSaleInfoCard(cards[i]?._sale);
                    console.log("DA sale ingo" , saleInfo , cards[i]?._sale);
                    if (saleInfo?.useWithToken == "0x0000000000000000000000000000000000000000") {
                        saleInfo = { ...saleInfo, ...{ coin: "BNB" } };
                        SaleInfoCards.push(saleInfo);
                        console.log("bnb", SaleInfoCards);
                    }
                    else {
                        const newdata = await UseTokenInfo(saleInfo?.useWithToken);
                        saleInfo = { ...saleInfo, ...{ coin: newdata?.symbol } };
                        SaleInfoCards.push(saleInfo);
                    }
                }
                else{
                    console.log("launchpad limit ret" , SaleInfoCards );
                    return SaleInfoCards;
                }
            }
            console.log("launchpad limit ter" , SaleInfoCards );
            return SaleInfoCards;
        }
    }
    catch(e){
        console.log("Error on get launchpadlimit" , e ,SaleInfoCards);
        return SaleInfoCards
    }
}


export const calculateBuy = async(saleAddress,bnbValue)=>{
    const saleContact = await UseSale(saleAddress);
    const bnbValues = toFixedNumber(bnbValue * 10 **18);
    const willGet = await saleContact.methods.getTokensPerEth(bnbValues.toString()).call();
    return willGet;
}


export const BuyToken = async(saleAddress,useramount,isMax,maxNumber,account,decimal , buytoken  , payload)=>{
    console.log("buytoken data" , saleAddress,useramount,isMax,maxNumber,account,decimal);
    const web3 = await useWeb3();
    const saleContact = await UseSale(saleAddress);
    const amount = !isMax? toFixedNumber(useramount * 10 **decimal): maxNumber ;
    console.log("buytoken data" , amount);
    if(buytoken == "BNB")
        var data =  saleContact.methods.contribute(amount.toString() , "0x0000000000000000000000000000000000000000").send({ from: account,value: amount.toString() })
    else 
        var data =  saleContact.methods.contribute(amount.toString() , "0x0000000000000000000000000000000000000000").send({ from: account})
    // const data = web3.eth.sendTransaction({ from: account, to: saleAddress, value: amount.toString() })
    let result = await UserBuyHooks(payload)
    
    await toast.promise(data, {
        loading: 'Making a Buy Token Request...',
        success: 'Bougt Token Successfully',
        error: 'Error ! When Buying Token',
    }, {
        position:position.position,
    style:style,
    iconTheme: iconTheme
    }
    );
    window.location.reload();
}

export const claimToken = async(saleAddress,account) => {
    const saleContact = await UseSale(saleAddress);
    const data = saleContact.methods.claimTokens().send({ from: account });
    await toast.promise(data, {
        loading: 'Requesting for Claim Tokens...',
        success: 'Tokens Claimed Successfully',
        error: 'Error ! When Claiming Token',
    }, {
        position:position.position,
        style:style,
        iconTheme: iconTheme
    }
    );
}


export const updateStopEvent = async (saleAddress,account) =>{
    const saleContact = await UseSale(saleAddress);
    const data = saleContact.methods.closePresale().send({from: account});
    await toast.promise(data, {
        loading: 'Manual Stop Sale event ...',
        success: 'Sale Stopped Successfully',
        error: 'Error ! When Stopping sale',
    }, {
        position:position.position,
        style:style,
        iconTheme: iconTheme
    }
    );
}

export const Finalise = async (saleAddress,account) =>{
    const saleContact = await UseSale(saleAddress);
    const data = saleContact.methods.finalizeSale().send({from: account});
    await toast.promise(data, {
        loading: 'Finalizing the sale ...',
        success: 'Users can Claim their Tokens Now !',
        error: 'Error ! When Finalising',
    }, {
        position:position.position,
    style:style,
    iconTheme: iconTheme
    }
    );
}
export const getChainId = () => {
    try {
      const index = localStorage.getItem("CHAIN");
      return index != null ? index : "0";
    }
    catch (err) {
      console.log("getChainId", err)
    }
  }
export const Checkaddress = async (address) => {
    console.log("addresssess",address);
    try {
        const web3 = await useWeb3();
        const account = sessionStorage.getItem('accountInfo')

        let errors = {}
        
        const data = web3.utils.isAddress(address)
        console.log("checkaddress",data)
        if (data) {
            console.log("checkaddress")
            let tokeninfo = await UseTokenInfo(address);
            console.log("tokeninfo", tokeninfo);
            return tokeninfo;
            // const newad = web3.utils.toChecksumAddress(address);
            // const contract = new web3.eth.Contract(ERC20_ABI, newad);
            // console.log("tokeninfo",contract.methods,account, CHAINS[getChainId()].LOCK_ADDRESS);
            // const allowance = await contract.methods.allowance(account, CHAINS[getChainId()].LOCK_ADDRESS).call();
            // console.log("tokeninfo1",allowance);
            // const approvebutton = allowance > 0 ? true : false
            // console.log("checkaddress", tokeninfo);
            // return {
            //     isValid: true,
            //     tokeninfo: tokeninfo,
            //     allowance: approvebutton
            // }
        }
        else {
            // errors.tokenaddress = "Invalid address !"
            // return {
            //     isValid: false,
            //     errors: errors
            // };
        }
    }
    catch (e) {
        console.log("Checkaddress", e)
    }
}



export const getUnsold = async (saleAddress,account) =>{
    const saleContact = await UseSale(saleAddress);
    const data = saleContact.methods.getUnsoldTokens().send({from: account});
    await toast.promise(data, {
        loading: 'Withraw Un Sold Tokens ...',
        success: 'Withdrawn Successfully',
        error: 'Error ! When Withdraw',
    }, {
        position:position.position,
        style:style,
        iconTheme: iconTheme
    }
    );
}

export const withdrawBNB = async (saleAddress,account) =>{
    const saleContact = await UseSale(saleAddress);
    const data = saleContact.methods.withdrawBNB().send({from: account});
    await toast.promise(data, {
        loading: 'Withraw BNB ...',
        success: 'Withdrawn BNB Successfully',
        error: 'Error ! When Withdraw BNB',
    }, {
        position:position.position,
    style:style,
    iconTheme: iconTheme
    }
    );

}


export const addWhitelistMembers = async (address , amount ,saleAddress,account) =>{
    console.log("contract" , saleAddress,account);
    const saleContact = await UseSale(saleAddress);
    const data = saleContact.methods.addMultipleWhitelistedAddresses([address],[(parseFloat(amount)*10**18).toString()]).send({from: account});
    await toast.promise(data, {
        loading: 'Adding Whitelist Members ...',
        success: 'User(s) Whitelisted Successfully',
        error: 'Error ! When Whitelisting Users',
    }, {
        position:position.position,
    style:style,
    iconTheme: iconTheme
    }
    );

}

//native
export const UseNativeSale = async (VALUE) => {
    console.log("VALUE",VALUE);
    try {
        const web3 = await useWeb3();
        const contract = new web3.eth.Contract(NATIVE_SALE_ABI, VALUE);
        return contract;
    }
    catch (err) {
        console.log("UseSale", err.code)
    }
}


export const calculateNativeBuy = async(saleAddress,bnbValue)=>{
    const saleContact = await UseNativeSale(saleAddress);
    const bnbValues = toFixedNumber(bnbValue * 10 **18);
    const willGet = await saleContact.methods.getTokensPerEth(bnbValues.toString()).call();
    console.log("willGet" , parseFloat(willGet)/10**18);
    return willGet;
}


// export const GetNativeSalePerAccount = async (account, saleAddress) => {
//     console.log("User_data " ,account, saleAddress )
//     try {
//         const saleContract = await UseNativeSale(saleAddress);
//         const userData = await saleContract.methods.getUserInfo(account).call();
//         return userData;
//     }
//     catch (err) {
//         console.log("GetSalePerAccount", err.code)
//     }
// }

export const BuyNativeToken = async(saleAddress,useramount,isMax,maxNumber,account,decimal , buytoken )=>{
    console.log("buytoken data" , saleAddress,useramount,isMax,maxNumber,account,decimal);
    // const web3 = await useWeb3();
    // const saleaddress = NATIVETOKEN_SALE;
    // const amount = !isMax? toFixedNumber(useramount * 10**18): maxNumber ;
    // const data = web3.eth.sendTransaction({ from: account, to: saleaddress, value: amount.toString() })
    
    // await toast.promise(data, {
    //     loading: 'Making a Buy Token Request...',
    //     success: 'Bougt Token Successfully',
    //     error: 'Error ! When Buying Token',
    // }, {
    //     position:position.position,
    // style:style,
    // iconTheme: iconTheme
    // }
    // );


    console.log("buytoken data" , saleAddress,useramount,isMax,maxNumber,account,decimal);
    const web3 = await useWeb3();
    const saleContact = await UseNativeSale(saleAddress);
    const amount = !isMax? toFixedNumber(parseFloat(useramount)*10**18 ): maxNumber ;
    if(buytoken == "BNB")
        var data =  saleContact.methods.contribute(amount.toString() , "0x0000000000000000000000000000000000000000").send({ from: account,value: amount.toString() })
    else 
        var data =  saleContact.methods.contribute(amount.toString() , "0x0000000000000000000000000000000000000000").send({ from: account  ,value: amount.toString()})
    // const data = web3.eth.sendTransaction({ from: account, to: saleAddress, value: amount.toString() })
    await toast.promise(data, {
        loading: 'Making a Buy Token Request...',
        success: 'Bougt Token Successfully',
        error: 'Error ! When Buying Token',
    }, {
        position:position.position,
        style:style,
        iconTheme: iconTheme
    }
    );
    window.location.reload();
}

export const GetNativeSaledata = async() => {
    try{
        const saleContact = await UseNativeSale(NATIVETOKEN_SALE);
        let tokenprice = await saleContact.methods.tokenRatePerEth().call();
        let tokenaddress = await saleContact.methods.tokenAddress().call();
        let tokeninfo = await UseTokenInfo(tokenaddress);
        let min = await saleContact.methods.minEthLimit().call();
        let max = await saleContact.methods.maxEthLimit().call();
        let name = await saleContact.methods.getname().call();
        let logo = await saleContact.methods.getlogo().call();

        let data = {
            tokenprice : tokenprice,
            tokenaddress : tokenaddress,
            tokeninfo : tokeninfo,
            min : min,
            max : max,
            name : name,
            logo : logo
        }
console.log("native sale dataaafdasfgs" , data);
        return data;
    }
    catch(e){
        console.log("Error on get native sale data" , e);
    }
}

export const GetNativeSale = async() => {
    try{
        const salecontract = await UseNativeSale()
        console.log("salecontract.methods" ,salecontract.methods);
        var saleInfo =  await salecontract.methods.getSaleInfo().call(); //await getSaleInfoCard(NATIVETOKEN_SALE);
        if (saleInfo?.useWithToken == "0x0000000000000000000000000000000000000000") {
            saleInfo = { ...saleInfo, ...{ coin: "BNB" } };
            return saleInfo
        }
        else {
            const newdata = await UseTokenInfo(saleInfo.useWithToken);
            saleInfo = { ...saleInfo, ...{ coin: newdata.symbol } };
            return saleInfo
        }
    }
    catch(E){
        console.log("Error on get native sale" , E);
    }
}


export const Getbalance = async()=>{
    const web3 = await useWeb3();
    const account =  getAccount();
    console.log("amount" , account);
    
    if(account){
        const balance = await web3.eth.getBalance(account);
        return balance/10**18;
    }
    else{
        return null;
    }
    
  }

  export const approveContractbuynative = async (account, token, presaleaddress) => {

    const erc20Contract = await UseERC20(token);
    const symbol = await erc20Contract.methods.symbol().call();
    const data = erc20Contract.methods.approve(presaleaddress, "115792089237316195423570985008687907853269984665640564039457584007913129639935").send({ from: account })
    await toast.promise(data, {
        loading: `Approving ${symbol} token...`,
        success: 'Approved Successfully',
        error: 'Try Again',
    }, {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
    })
}


export const RefundUSDT = async(saleAddress) => {
    const saleContact = await UseSale(saleAddress);
    const data = saleContact.methods.refund().send({ from: getAccount() });
    await toast.promise(data, {
        loading: 'Requesting for Refund Tokens...',
        success: 'Tokens Refunded Successfully',
        error: 'Error ! When Refunded Token',
    }, {
        position:position.position,
        style:style,
        iconTheme: iconTheme
    }
    );
}


export const CheckRegisterInterest = async(saleAddress) => {
    const saleContact = await UseSale(saleAddress);
    const data = await saleContact.methods.CheckInterest(getAccount()).call();
    return data;
}

export const AddRegisterInterest = async(saleAddress) => {
    const saleContact = await UseSale(saleAddress);
    console.log("saleContact" , saleContact);
    const data =  saleContact.methods.AddInterest().send({from: getAccount()});
    await toast.promise(data, {
        loading: 'Registering Interest...',
        success: 'Registered Interest Successfully',
        error: 'Error ! When Registered',
    }, {
        position:position.position,
        style:style,
        iconTheme: iconTheme
    }
    );
    window.location.reload();
}

export const GetRegisteredUsers = async (saleAddress) => {
    try {
        const saleContact = await UseSale(saleAddress);
        const data = saleContact.methods.GetInterestUsers().call();
        return data;
    }
    catch (err) {
        console.log("Finalise", err)
    }
}