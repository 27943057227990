import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import Header from '../Layouts/header';
import bannerimage from '../images/spropad/bannerimage.png';
import CountUp from 'react-countup';
import projvec from '../images/spropad/projvector.png';
import pausebtn from '../images/spropad/pausebtn.png';
import tableprof from '../images/spropad/tableprof.png';
import spaceleft from '../images/spropad/spaceleft.png';
import spaceround from '../images/spropad/spaceround.gif';
import greenround from '../images/spropad/greencircle.gif';
import twinkle from '../images/spropad/bannertwinkle.gif';
import featureimg from '../images/spropad/featureimg1.png';
import spacegif from '../images/spropad/spacegif.gif';
import nerve from '../images/spropad/nerve.gif';
import flash from '../images/spropad/flash.gif';
import register from '../images/spropad/register.png';
import whtround from '../images/spropad/whtround.png';
import { IoSearchOutline } from "react-icons/io5";
import Tokencard from "../Components/tokencard";
import Footer from '../Layouts/footer';
import Hometablee from '../Components/hometable';
import Poolscard from '../Components/poolscard';
import { Link, useNavigate } from 'react-router-dom';
import Subheaders from "../Layouts/homesubheader";
import Faqhome from "../Components/faqhome";
import Walletmodal from '../Components/walletmodal';
import cardsimg from "../images/spropad/cardsimg.png";
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import binance from "../images/spropad/binance.png";
import Countdown from 'react-countdown';
import featuresetting from "../images/spropad/feature_setting.png"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
//sonia
import { getPoolInfoLimit, getPoolLength, getUser } from '../Hooks/useStake';
import { getAccount } from '../Hooks/useAccounts';
import { GetLaunchpadLimit, GetNativeSale, GetNativeSaledata, UseTokenInfo, getTotalSalesInfo, getlaunchpaddatalimit } from '../Hooks/useContract';
import { FaqhideList, getfaqlist, getregisteraccount, getsociallinks, sprofeatureList } from '../landingaction/landing';
import { IMAGE_URL, NATIVETOKEN_SALE } from '../Config/env';
import Buyflowmodal from '../Components/buyflowmodal';
import { getTotalNativeSalesInfo, getnativelaunchpaddatalimit } from '../Hooks/UseNativesale';


function Homepage() {
    const navigate = useNavigate();
    const[buysflow,setBuysflow] = useState(false);
    const [walletmodal, setWalletmodal] = useState(false);
    const [salecard, setSalecard] = useState([]);
    console.log("Salecard", salecard);

    const [tokencards, setTokencards] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }
    ])
    const [volumess, setvolumess] = useState(40)
    const handleOnChange = (value) => {
        setvolumess(value)
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    const sliderStyles = {
        pointerEvents: 'none', // Disables all pointer events
        opacity: 0.9 // Optionally, reduce opacity to visually indicate it's non-interactive
    };

    const options = {
        margin: 20,
        responsiveClass: true,
        nav: false,
        dots: true,
        autoplay: false,
        smartSpeed: 1000,
        items: 1,
    };

    const optionss = {
        margin: 20,
        responsiveClass: true,
        nav: true,
        dots: true,
        autoplay: false,
        smartSpeed: 1000,
        items: 1,
    };

    const [owllist, setOwllist] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 },
    ])
    //sonia


    const [poolLimit, setPoolLimit] = useState([]);
    const [accountInfo, setAccountInfo] = useState(getAccount());
    console.log("accountInfo", accountInfo);


    useEffect(() => {
        getData();
        saledata()
    }, [])


    const getData = async () => {
        let data = await getPoolLength()
        console.log("data", data);
        const pool = await getPoolInfoLimit(0, data)
        console.log("pool", pool);
        if (pool) {
            setPoolLimit(pool);
        }
    }

    // const saledata = async () => {
    //     const Proxydata = await getTotalSalesInfo();
    //     console.log("Proxydata", Proxydata);

    //     setSalecard(Proxydata)
    // }
    useEffect(() => {
        NativeSale()
        FetchCms()
        getRegisteraccount()
        getSalelaunchpad()
        getstakingpool()
        getfeature()
        getlaunchspropad()
        getfaqheading()
        getRegisteriamge()
        GetBuytokeninfo()
        loadmoredata()
        totalsaleInfo()
        getRoadmapimage()
        saledata()
    }, [])
    const [welcome, setWelcome] = useState([])
    const [registeracc, setRegisteracc] = useState([])
    const [salelaunchpad, setSalelaunchpad] = useState([])
    const [stakepool, setStakepool] = useState([])
    const [stakeFeature, setStakeFeature] = useState([])
    const [launchspropad, setLaunchspropad] = useState([])
    
    const [faqheading, setFaqheading] = useState([])
    const [registerimg, setRegisterimg] = useState([])
    const [roadmapimg, setRoadmapimg] = useState([])
    console.log("roadmapimg", roadmapimg)

    const [proxy, setProxy] = useState([])
  

    const [searchproxy, setSearchproxy] = useState([]);
    const [searchsale, setSearchsale] = useState([]);
    const [tokenaddress, setTokenaddress] = useState('')
    const [saleaddress, setSaleaddress] = useState([])
    const [original, setOriginal] = useState([])
    const [loadinterval, setLoadinterval] = useState(3)
    const [totalSales, setTotalSales] = useState([])
    const [loading , setLoading] = useState(false);
    const [inititalloading , setInitialLoading] = useState(false);

    const [completed , setCompleted] = useState([]);
    const [completedproxy , setCompletedproxy] = useState([]);
    const [nativesale , setNativesale] = useState([]);
    const [nativetokeninfo , setNativetokeninfo] = useState({});
    const [nativetype , setNativetype] = useState("");
    const [livesaleinfo , setLivesaleinfo] = useState([]);
//new native sale concept change
    const [privatenativesale , setPrivatenativesale] = useState({});
    const [privatenativetokeninfo , setPrivatenativetokeninfo] = useState({});
    const [selectedsale , setSelectedsale] = useState({});
    const [nativeloader , setNativeloader] = useState(false);
    const [nativeproxy , setNativeproxy] = useState([]);
    const [refresh , setRefresh] = useState(0);
//
    const totalsaleInfo = async () => {
        const Proxydata = await getTotalSalesInfo();
        console.log("Proxydata11", Proxydata)
        setSaleaddress(Proxydata)

        let newtotalproxy = [];
        Proxydata?.map((val, i) => {
            let singledata = Proxydata?.find(e => e?._sale == val?.saleaddress);
            if (singledata) {
                newtotalproxy.push(singledata)
            }
        })

        Proxydata?.map((datas, i) => {
            let singlesale = newtotalproxy?.find(e => e._sale == datas?._sale);
            if (!singlesale) {
                newtotalproxy.push(datas)
            }
        })


        setProxy(newtotalproxy)
        const total = await getlaunchpaddatalimit(newtotalproxy, 0, loadinterval);
        console.log("total----", total);

        setTotalSales(total);
        setInterval(loadinterval);
        setLoading(false)
    }

    const loadmoredata = async () => {
        try {
            setLoading(true);
            if (proxy?.length > salecard?.length) {
                let proxydata = await GetLaunchpadLimit(proxy, salecard?.length, (proxy?.length > salecard?.length + 10) ? (salecard?.length + 10) : proxy?.length);
                setSalecard(salecard?.concat(proxydata));
            }
            setLoading(false);
        }
        catch (e) {
            console.log("Error on load more data", e);
        }
    }

    const GetBuytokeninfo = async (value) => {
        try {
            console.log("valuetargetss", value);
            const tokendata = await UseTokenInfo(value);
            console.log("tokendata", tokendata);
            setTokenaddress(tokendata);


        } catch (e) {
            console.log("error", e);
        }



    }

    


    console.log("welcomess", welcome);
    const FetchCms = async () => {
        let Resp = await getfaqlist({ data: "homepage_box1" })
        console.log("sdfsdgdddd", Resp.data.data.data);
        if (Resp?.data.status) {
            setWelcome(Resp.data.data.data)

        }

    }

    const getRegisteraccount = async () => {
        let Resp = await getregisteraccount()
        console.log("getregisteraccount", Resp.data.data.data);
        if (Resp?.data.status) {
            setRegisteracc(Resp.data.data.data)

        }

    }

    const getSalelaunchpad = async () => {
        let Resp = await getfaqlist({ data: "homepage_box2" })
        console.log("setSalelaunchpad", Resp.data.data.data);
        if (Resp?.data.status) {
            setSalelaunchpad(Resp.data.data.data)

        }

    }

    const getstakingpool = async () => {
        let Resp = await getfaqlist({ data: "homepage_box3" })
        console.log("setStakepool", Resp.data.data.data);
        if (Resp?.data.status) {
            setStakepool(Resp.data.data.data)

        }

    }

    const getfeature = async () => {
        let Resp = await sprofeatureList()
        console.log("sprofeatureList", Resp.data.data.data);
        if (Resp?.data.status) {
            setStakeFeature(Resp.data.data.data)

        }

    }

    const getlaunchspropad = async () => {
        let Resp = await getfaqlist({ data: "launch_spropad" })
        console.log("launch_spropad", Resp.data.data.data);
        if (Resp?.data.status) {
            setLaunchspropad(Resp.data.data.data)
        }
    }

    const getfaqheading = async () => {
        let Resp = await getfaqlist({ data: "homepage_box4" })
        console.log("setFaqheading", Resp.data.data.data);
        if (Resp?.data.status) {
            setFaqheading(Resp.data.data.data)
        }
    }

    const getRegisteriamge = async () => {
        let Resp = await getfaqlist({ data: "registeraccountimg" })
        console.log("getRegisteriamge", Resp.data.data.data);
        if (Resp?.data.status) {
            setRegisterimg(Resp.data.data.data)
        }
    }

    const getRoadmapimage = async () => {
        let Resp = await getfaqlist({ data: "roadmap" })
        console.log("getRoadmapimage", Resp.data.data.data);
        if (Resp?.data.status) {
            setRoadmapimg(Resp.data.data.data)
        }
    }


    // const getFaqall = async () => {
    //     let get = await FaqhideList();
    //     console.log("setAllfaq", get,get?.data?.data?.data);
    //     if (get?.data?.data?.status == true) {
    //         setAllfaq(get?.data?.data?.data);
    //         console.log("faqdata", get?.data?.data?.data)
    //     }
    // }


// murugavel

const isUpcoming = (start,end) =>{
    return (Date.now() < (start*1000));
}
const isSaleLive = (start, end, isOpen) => {
    try {
        return (Date.now() >= (start * 1000) && Date.now() <= (end * 1000)) && isOpen;
    }
    catch (e) {
        console.log("isSaleLive", e);
    }
}

const NativeSale = async() => {
    try{
        // debugger
        setNativeloader(true);
        const Proxydata = await getTotalNativeSalesInfo();
        const salerecords = await getnativelaunchpaddatalimit(Proxydata, 0, Proxydata?.length);
        console.log("nativesale" , salerecords);
            setNativeproxy(Proxydata)
            setNativesale(salerecords);
            setNativeloader(false)
            setTimeout(() => {
                setNativesale(salerecords);
            } , 1000);
       
            setRefresh(refresh+1)
    }
    catch(e){
        console.log("Errro on native sale" , e);
    }
}

const saledata = async () =>{
    setInitialLoading(true)
    var newsaleinfo = [];
    var endedsaleinfo = [];
    var livesaleinfo = [];
    const Proxydata = await getTotalSalesInfo();
    console.log("Proxydata", Proxydata);


    await Proxydata?.map((data , i ) => {
        var valid = isUpcoming(data._start)
        valid && newsaleinfo.push(data);


        //ended

        var endvalid = data?._end*1000 < Date.now();
        endvalid && endedsaleinfo.push(data);

        //live
        var livevalid = isSaleLive(data?._start , data?._end , true)
        livevalid && livesaleinfo.push(data);
    })
    setProxy(Proxydata);
    setCompletedproxy(endedsaleinfo);
    console.log("newsaleinfo" , newsaleinfo , endedsaleinfo);
    let limitproxydata = await GetLaunchpadLimit(newsaleinfo , 0 , newsaleinfo?.length > 5 ? 5 : newsaleinfo?.length);
    let endlimitproxydata = await GetLaunchpadLimit(endedsaleinfo , 0 , endedsaleinfo?.length > 5 ? 5 : endedsaleinfo?.length);
    let livelimitproxydata = await GetLaunchpadLimit(livesaleinfo , 0 , livesaleinfo?.length > 6 ? 6 : livesaleinfo?.length);
    console.log("limitproxydata" , limitproxydata , endlimitproxydata);
    setSalecard(limitproxydata?.length > 0 ? limitproxydata : [])
    setCompleted(endlimitproxydata?.length > 0  ? endlimitproxydata : []);
    setLivesaleinfo(livelimitproxydata?.length > 0 ? livelimitproxydata : []);
    setInitialLoading(false)
}


const loadmoredatacomplete = async() => {
    try{
        setLoading(true);
        if(proxy?.length > salecard?.length){
            let proxydata = await GetLaunchpadLimit(completedproxy , salecard?.length , (completedproxy?.length > salecard?.length +5) ?  (salecard?.length+5) : completedproxy?.length);
           console.log("loadmore data" , proxydata);
            setCompleted(completed?.concat(proxydata));
        }
        setLoading(false);
    }
    catch(e){
        console.log("Error on load more data" , e);
    }
}


//end murugavel




console.log("native sale state" , nativesale);






    return (
        <>
        {buysflow && <Buyflowmodal onDismiss={() => setBuysflow(false)} saledetail = {selectedsale}/>}
            {walletmodal && <Walletmodal onDismiss={() => setWalletmodal(false)} />}
            <div className='all'>

                <Header />
                <Subheaders />

                <div className='Homesections'>

                    <div className='container'>
                        <div className='banner pt-5 pb-5'>
                            <div className='row align-items-center'>
                                <div className='col-md-12 col-xl-6 mt-4'>
                                    <div className='px-1 px-md-5 px-xl-3 px-xxl-5'>
                                        <h1 className='fname'>Welcome</h1>
                                        <h1 className='sectext'>{welcome?.heading}</h1>
                                        <p className='subpara'><div dangerouslySetInnerHTML={{
                                            __html: welcome?.description,
                                        }}></div>
                                        </p>
                                        <div className='d-block d-sm-flex'>
                                            <Link to="/allpools"><Button className='themesbtn mt-3'><span>IDO POOLS</span></Button></Link>
                                            <Link to="/staking"><Button className='themesbtn mx-0 mx-sm-3  mt-3'><span>STAKE $SPP</span></Button></Link>
                                            <Link target="_blank"to="https://t.me/SmartProPad"><Button className='themesbtn  mt-3'><span>GET NOTIFIED</span></Button></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 col-xl-6 mt-4 pos'>
                                    <img src={twinkle} className='img-fluid twinkle' />
                                    <img src={welcome?.Hometopimage ? `${IMAGE_URL}/Hometopimg/${welcome?.Hometopimage}` : bannerimage} className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='container'>
                        <div className='counts py-0'>

                            <div className='row allrow'>
                                <div className='col-xl-11 col-xxl-10 projectlist mx-auto pos'>
                                    <img src={greenround} className='greenround img-fluid' />
                                    <div className='padl'>
                                        <div className='row'>
                                            <div className='col-6 col-sm-6 col-md-3 mt-3 pos'>
                                                <h1 className='countno'><CountUp end={proxy?.length ? proxy?.length : 0} /></h1>
                                                <h4 className='proj'>IDO Projects</h4>
                                                <img src={projvec} className='projvec img-fluid' />
                                            </div>
                                            <div className='col-6 col-sm-6 col-md-3 mt-3 pos'>
                                                <h1 className='countno'><CountUp end={5} /></h1>
                                                <h4 className='proj'>Staked</h4>
                                                <img src={projvec} className='projvec img-fluid' />
                                            </div>
                                            <div className='col-6 col-sm-6 col-md-3 mt-3 pos'>
                                                <h1 className='countno'><CountUp end={proxy?.length ? proxy?.length : 0} /></h1>
                                                <h4 className='proj'>pools</h4>
                                                <img src={projvec} className='projvec img-fluid' />
                                            </div>
                                            <div className='col-6 col-sm-6 col-md-3 mt-3'>
                                                <h1 className='countno'><CountUp end={35} /></h1>
                                                <h4 className='proj'>locked</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='register pt-3 pb-3'>
                        <img src={flash} className='flash img-fluid' />

                        <div className='container pos'>
                            <img src={spaceleft} className='spaceleft img-fluid' />
                            <img src={spaceround} className='spaceround img-fluid' />
                            <div className='row justify-content-center'>
                                <div className='col-md-12 col-lg-5 col-xl-5 mt-4'>
                                    <img src={registerimg?.Registerimage ? `${IMAGE_URL}/Registerimage/${registerimg?.Registerimage}` : register} className='registerimg img-fluid w-100' />
                                </div>
                                <div className='col-md-9 col-lg-6 col-xl-5 mt-4'>

                                    <OwlCarousel className='owl-theme' {...options}>
                                        {registeracc.map((e, i) =>
                                            <div class='item'>
                                                <div>
                                                    <div className='regsbtn'>
                                                        <h1 className='mainhead text-center regtext'>{e?.question?.split(" ")?.slice(0,-1)} <span className='mainsub'>{(e?.question)?.split(" ")?.slice(-1)}</span></h1>
                                                    </div>
                                                    <p className='subpara subparapl mt-3'><div dangerouslySetInnerHTML={{
                                                        __html: e?.answer,
                                                    }}></div></p>
                                                    {localStorage.getItem("metamask") !== "true" ?
                                                        <h4 className='wallettext' onClick={() => { setWalletmodal(true) }}>Connect Your Wallet <img src={pausebtn} className='pausebtn img-fluid ms-2' /> </h4> :
                                                        <Link to="/profile"><h4 className='wallettext'>Register your account 
                                                        {/* <img src={pausebtn} className='pausebtn img-fluid ms-2' />  */}
                                                        <img src={pausebtn} className='pausebtn img-fluid ms-2' /> 

                                                        </h4></Link>}
                                                </div>
                                            </div>
                                        )}
                                    </OwlCarousel>


                                    {/* <div>
                                   <div className='regsbtn'>
                                        <h1 className='mainhead text-center regtext'>Register your <span className='mainsub'>Account</span></h1>
                                    </div>
                                    <p className='subpara subparapl mt-3'>Lorem ipsum dolor sit amet consectetur. Vitae sem consectetur pulvinar vulputate tortor a enim. Ut gravida lorem leo ipsum. Sagittis et sed. Lorem ipsum dolor sit amet consectetur. Vitae sem consectetur pulvinar vulputate tortor a enim. Ut gravida lorem leo ipsum. Sagittis et sed.</p>
                                    {localStorage.getItem("metamask") !== "true" ?
                                        <h4 className='wallettext' onClick={() => { setWalletmodal(true) }}>Connect Your Wallet <img src={pausebtn} className='pausebtn img-fluid ms-2' /> </h4> :
                                        <h4 className='wallettext' onClick={() => { setWalletmodal(true) }}>Register your account <img src={pausebtn} className='pausebtn img-fluid ms-2' /> </h4>}
                                   </div> */}


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='container pb-2'>
                        <div className='tokensale pt-5 pb-5'>
                            <h1 className='mainhead text-center alltext mb-4'>{salelaunchpad?.heading?.split(" ")?.slice(0,-1)}<span className='mainsub ms-1'>{salelaunchpad?.heading?.split(" ")?.slice(-1)}</span></h1>
                            <div className='row'>
                                <div className='col-md-8 col-xl-7 mx-auto'>
                                    <p className='proj text-center mt-3'><div dangerouslySetInnerHTML={{ __html: salelaunchpad?.description}}></div></p>
                                    <h1 className='fname text-center mt-4 mb-4'>Project list</h1>
                                </div>
                            </div>


                            {/* <div className='row mt-2'>
                                <div className='col-md-4 col-xl-3 mx-auto pos'>
                                    <IoSearchOutline className='searchicon' />
                                    <input type='search' className='form-control' placeholder='Search by pool name, token symbol...' />

                                </div>
                            </div> */}

                            <div className='native_sales pt-4 pb-5'>
                                <h5 className='proj text-center mt-4 mb-4'>Native Token Sale<span className="timelinecircle ms-2"></span></h5>
                               

                                {refresh && nativesale &&<OwlCarousel className='owl-theme' {...optionss}>
                                {refresh && nativesale && nativesale?.length > 0 ? nativesale?.map((data , i) => 
                               
                                <div class='item'>
                                    <div className='row'>
                                    <div className='col-xl-11 col-xxl-9 mx-auto'>
                                <div className='livepools py-3 pt-sm-5 px-3 px-sm-5'>
                                                <div className='row'>
                                                    <div className='col-md-6 pos mt-4'>
                                                        <img src={data?.banner} className='img-fluid livecardimg' />
                                                        {/* <p className='whtsclr endin'>End in  <Countdown date={Date.now() + 10000000} /></p> */}
                                                    </div>
                                                    <div className='col-md-6 mt-4'>
                                                        <div className='d-flex align-items-start'>
                                                            <img src={data?.logo} className='img-fluid imgprof me-3' />
                                                           { console.log("native sale" , data)}
                                                            <div><h4 className='fname'>{data && data?.name}</h4></div>
                                                        </div>
                                                        <div className='mt-3'>
                                                            {/* <div className='d-flex gap-4 align-items-center'><p className='grayclr'>Total raise</p><p className='whtsclr'>{parseFloat(nativesale?.earnedCap) / 10**parseFloat(nativetokeninfo?.decimals)}</p></div> */}
                                                            <div className='d-flex gap-4 align-items-center'><p className='grayclr'>Token Symbol</p><p className='whtsclr'>{data?.symbol}</p></div>
                                                            <div className='d-flex gap-4 align-items-center'><p className='grayclr'>Token Price per USDT</p><p className='whtsclr'>{parseFloat(data?.presaleRate)/10**18}</p></div>
                                                            <div className='d-flex gap-4 align-items-center'><p className='grayclr'>Network</p><p className='whtsclr'><img src={binance} className='img-fluid bnb me-2' style={{ display:"unset",width:"unset" }} /> BSC</p></div>
                                                            <div className='d-flex gap-4 align-items-center'><p className='grayclr'>Sale Type </p><p className='whtsclr'>{data?.name}</p></div>
                                                            <div className='d-flex gap-4 align-items-center'><p className='grayclr'>Sale Status </p><p className='whtsclr'>{data && isSaleLive(data?.startTime, data?.endTime, data?.isPresaleOpen) ?
                        


                        <span className="badge badge_live badge_finish">

                            <span className='stats_txt'><h5>Sales Live</h5></span>
                        </span> :
                        (data && isUpcoming(data.startTime) ?
                            <span className="badge  badge_finish">


                                <span className='stats_txt'><h5>Upcoming</h5></span>
                            </span> :


                            <span className="badge badge_finish">

                                <span className='stats_txt'><h5>Sales End</h5></span>
                            </span>
                        )
                    }</p></div>

                                                            
                                                        </div>
                                                        {/* <div className='mt-3'>
                                                            <p className='grayclr'>Progress</p>
                                                            <div style={sliderStyles}>
                                                                <Slider
                                                                    min={0}
                                                                    max={100}
                                                                    value={volumess}
                                                                    onChange={handleOnChange}
                                                                    tooltip={false}
                                                                />
                                                            </div>
                                                            <p className='whtsclr'>({volumess}%) 8234/12456 Lorem</p>
                                                        </div> */}
                                                        <div className='text-center'> <Button className='themesbtn mt-4 mb-3 mx-auto' onClick={()=>{
                                                            // setSelectedsale(nativesale);setBuysflow(true);
                                                            navigate(`/native-card-info/${data?.saleAddress}/public`)
                                                            }}><span>Buy</span></Button></div>

                                                    </div>
                                                </div>

                                            </div>
                                            </div>
                                            </div>
                                    </div>) : <h5 className='d-flex justify-content-center whtclr mb-0 py-5'> No Native Sale</h5>}
                                    {/* {nativeloader && nativesale?.length <= 0 && <div className='d-flex justify-content-center'>
                    <span class="loader"></span>
                    </div>} */}
                                    </OwlCarousel>}

{nativeloader && nativesale?.length <= 0 && <div className='d-flex justify-content-center'>
                    <span class="loader"></span>
                    </div>}
                               
                            </div>

                            

                            <div>
                            <h5 className='proj text-center mt-4 mb-0'>LIVE POOL IDO</h5>

<div className='row mt-2'>
    {livesaleinfo?.length > 0  ? livesaleinfo.map((val, index) => (<>
        {console.log("eval",val)}
        <div className='allcard col-md-12 col-lg-6 col-xl-6 col-xxl-4 mt-5'>
            <Tokencard saleData={index} datas={val} />
        </div>
        </>)) 
        
        : <h5 className='d-flex justify-content-center whtclr mb-0 py-5'> No Live Sale</h5>
    }
</div>
                            </div>

                            <h5 className='proj text-center mt-4 mb-0'>UPCOMING POOL IDO</h5>

                            <div className='row mt-2'>
                                {salecard?.length > 0  ? salecard.map((val, index) => (<>
                                    {console.log("eval",val)}
                                    <div className='allcard col-md-12 col-lg-6 col-xl-6 col-xxl-4 mt-5'>
                                        <Tokencard saleData={index} datas={val} />
                                    </div>
                                    </>)) 
                                    
                                    : <h5 className='d-flex justify-content-center whtclr mb-0 py-5'> No Upcoming Sale</h5>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='container sales pt-5 pb-5'>
                        <h1 className='mainhead text-center alltext mb-4'>Completed <span className='mainsub'> Sales</span></h1>
                        <br /><br />
                        <Link to="/allpools"><h5 className='whtclr viewall d-flex align-items-center justify-content-end'>View all pools <img src={pausebtn} className='pausebtn img-fluid ms-2' /> </h5></Link>
                       {completed?.length > 0 ?  <Hometablee proxy = {completedproxy} endedsale = {completed} loadmore = {() => loadmoredatacomplete()}/>
                      : <h5 className='d-flex justify-content-center whtclr mb-0 py-5'> No Completed Sale</h5> 
                    }
                    </div>
{/* staking commet for only native sale deployment */}
                    <div className='stakingpools pb-5'>
                        <img src={flash} className='flash img-fluid' />
                        <div className='container pt-5 pb-5'>
                            <h1 className='mainhead text-center alltext mb-4'>{stakepool?.heading?.split(" ")?.slice(0,-1)} <span className='mainsub'>{stakepool?.heading?.split(" ")?.slice(-1)}</span></h1>
                            <div className='row mb-4'>
                                <div className='col-md-8 col-xl-7 mx-auto'>
                                    <p className='proj text-center mt-3 mb-3'>{<div dangerouslySetInnerHTML={{ __html: stakepool?.description}}></div>}</p>
                                </div>
                            </div>
                            <div className='row pb-4'>
                                {poolLimit
                                    && poolLimit.map((val, index) =>

                                        <Poolscard datas={val} pid={index} accountInfo={accountInfo} />

                                    )}
                            </div>

                            <Link to="/staking"><Button className='themesbtn mt-4 mx-auto'><span>Stake Now</span></Button></Link>
                        </div>
                    </div>

                    <div className='features pt-5 pb-5'>
                        <img src={flash} className='flash img-fluid' />
                        <img src={spacegif} className='spacegif img-fluid' />

                        <div className='container'>
                            <h1 className='mainhead text-center alltext mb-4'>Smart pro pad <span className='mainsub'> Features</span></h1>
                            <br /><br />
                            <div className='row px-3 px-md-0 pos d-flex justify-content-center'>
                                <img src={featureimg} className='img-fluid featureimg' />
                                <img src={greenround} className='greenroundss img-fluid' />
                                {/* <img src={featuresetting} className='featuresetting img-fluid' /> */}
                                <img src={roadmapimg?.roadmapimage ? `${IMAGE_URL}/Raodmap/${roadmapimg?.roadmapimage}` : featuresetting} className='featuresetting img-fluid'/>
                                <div className='featurepos feature1 py-3 py-sm-5 px-4 mt-4 mt-xl-0'>
                                    <h3 className='whtclr mt-2'><span className='num'>{stakeFeature?.find((val)=>val.step == 1)?.question?.split(" ")?.slice(0,-1)}</span> 
                                     <span className='dayclr ms-1'>{stakeFeature?.find((val)=>val.step == 1)?.question?.split(" ")?.slice(-1)}</span>
                                    </h3>
                                    <p className='subpara mb-0'><div dangerouslySetInnerHTML={{ __html: stakeFeature.find((val)=>val.step == 1)?.answer}}></div></p>
                                </div>
                                <div className='featurepos feature2 py-3 py-sm-5  px-4 mt-4 mt-xl-0'>
                                    <h3 className='whtclr mt-2'><span className='num'>{stakeFeature?.find((val)=>val.step == 2)?.question?.split(" ")?.slice(0,-1)}</span> 
                                    <span className='dayclr ms-1'>{stakeFeature?.find((val)=>val.step == 2)?.question.split(" ").slice(-1)}</span>
                                    </h3>
                                    <p className='subpara mb-0'><div dangerouslySetInnerHTML={{ __html: stakeFeature.find((val)=>val.step == 2)?.answer}}></div></p>
                                </div>
                                <div className='featurepos feature3 py-3 py-sm-5  px-4 mt-4 mt-xl-0'>
                                    <h3 className='whtclr mt-2'><span className='num'>{stakeFeature?.find((val)=>val.step == 3)?.question?.split(" ")?.slice(0,-1)}</span> <span className='dayclr'>{stakeFeature?.find((val)=>val.step == 3)?.question?.split(" ")?.slice(-1)}</span></h3>
                                    <p className='subpara mb-0'><div dangerouslySetInnerHTML={{ __html: stakeFeature?.find((val)=>val.step == 3)?.answer}}></div></p>
                                </div>
                                <div className='featurepos feature4 py-3 py-sm-5  px-4 mt-4 mt-xl-0'>
                                    <h3 className='whtclr  mt-2'><span className='num'>{stakeFeature?.find((val)=>val.step == 4)?.question?.split(" ")?.slice(0,-1)}</span> <span className='dayclr'>{stakeFeature?.find((val)=>val.step == 4)?.question?.split(" ")?.slice(-1)}</span></h3>
                                    <p className='subpara mb-0'><div dangerouslySetInnerHTML={{ __html: stakeFeature?.find((val)=>val.step == 4)?.answer}}></div></p>
                                </div>
                                <div className='featurepos feature5 py-3 py-sm-5  px-4 mt-4 mt-xl-0'>
                                    <h3 className='whtclr mt-2'><span className='num'>{stakeFeature?.find((val)=>val.step == 5)?.question?.split(" ")?.slice(0,-1)}</span> <span className='dayclr'>{stakeFeature?.find((val)=>val.step == 5)?.question?.split(" ")?.slice(-1)}</span></h3>
                                    <p className='subpara'><div dangerouslySetInnerHTML={{ __html: stakeFeature?.find((val)=>val.step == 5)?.answer}}></div></p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='launch_spropad container pt-5 pb-5'>
                        <div className='row py-3'>
                            <div className='col-xl-11 col-xxl-10 filterdrop mx-auto'>
                                <div className='launchback'>
                                    <img src={nerve} className='nerve nerve1 img-fluid' />
                                    <img src={nerve} className='nerve nerve2 img-fluid' />
                                    <h1 className='whtclr text-center px-3 px-sm-0'>{launchspropad?.heading}</h1>
                                    <div className='row mt-4 mt-md-5 mx-2 mx-lg-4 mb-4 mb-md-5'>
                                        <div className='col-md-4 d-flex align-items-center mt-3'>
                                            <img src={whtround} className='whtround img-fluid' />
                                            <h4 className='whtclr mb-0 ms-2'>{launchspropad?.answer1}</h4>
                                        </div>
                                        <div className='col-md-4 d-flex align-items-center mt-3'>
                                            <img src={whtround} className='whtround img-fluid' />
                                            <h4 className='whtclr mb-0 ms-2'>{launchspropad?.answer2}</h4>
                                        </div>
                                        <div className='col-md-4 d-flex align-items-center mt-3'>
                                            <img src={whtround} className='whtround img-fluid' />
                                            <h4 className='whtclr mb-0 ms-2'>{launchspropad?.answer3}</h4>
                                        </div>
                                    </div>

                                    <a 
                                    // onclick={() => window.open("http://www.foracure.org.au")}
                                    href="/tokenrequest"><Button className='themesbtn applybtn mt-3 mb-4'><span>Apply Launch</span></Button></a>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='faq pt-5 pb-5'>
                        <div className='container'>
                            <h1 className='mainhead text-center alltext mb-4'>{faqheading?.heading?.split(" ")?.slice(0,-1)} <span className='mainsub'> {faqheading?.heading?.split(" ")?.slice(-1)}</span></h1>
                            <div className='row mb-4'>
                                <div className='col-md-8 col-xl-7 mx-auto'>
                                    <p className='proj text-center mt-3 mb-3'><div dangerouslySetInnerHTML={{__html : faqheading?.description}}></div></p>
                                </div>
                            </div>

                            <Faqhome />

                        </div>

                    </div>

                    <br /><br />

                </div>

                <Footer />

            </div>
        </>
    )
}

export default Homepage;




